export function getCountriesList(locale: string) {
  switch (locale) {
    case 'de':
      return [
        {label: 'Auswählen', value: ''},
        {label: 'Afghanistan', value: 'Afghanistan'},
        {label: 'Ålandinseln', value: 'Aland Islands'},
        {label: 'Albanien', value: 'Albania'},
        {label: 'Algerien', value: 'Algeria'},
        {label: 'Andorra', value: 'Andorra'},
        {label: 'Angola', value: 'Angola'},
        {label: 'Anguilla', value: 'Anguilla'},
        {label: 'Antigua und Barbuda', value: 'Antigua And Barbuda'},
        {label: 'Argentinien', value: 'Argentina'},
        {label: 'Armenien', value: 'Armenia'},
        {label: 'Aruba', value: 'Aruba'},
        {label: 'Ascension', value: 'Ascension Island'},
        {label: 'Australien', value: 'Australia'},
        {label: 'Österreich', value: 'Austria'},
        {label: 'Aserbaidschan', value: 'Azerbaijan'},
        {label: 'Bahamas', value: 'Bahamas'},
        {label: 'Bahrain', value: 'Bahrain'},
        {label: 'Bangladesch', value: 'Bangladesh'},
        {label: 'Barbados', value: 'Barbados'},
        {label: 'Belarus', value: 'Belarus'},
        {label: 'Belgien', value: 'Belgium'},
        {label: 'Belize', value: 'Belize'},
        {label: 'Benin', value: 'Benin'},
        {label: 'Bermuda', value: 'Bermuda'},
        {label: 'Bhutan', value: 'Bhutan'},
        {label: 'Bolivien', value: 'Bolivia'},
        {label: 'Bosnien und Herzegowina', value: 'Bosnia And Herzegovina'},
        {label: 'Botsuana', value: 'Botswana'},
        {label: 'Bouvetinsel', value: 'Bouvet Island'},
        {label: 'Brasilien', value: 'Brazil'},
        {
          label: 'Britisches Territorium im Indischen Ozean',
          value: 'British Indian Ocean Territory',
        },
        {label: 'Brunei Darussalam', value: 'Brunei'},
        {label: 'Bulgarien', value: 'Bulgaria'},
        {label: 'Burkina Faso', value: 'Burkina Faso'},
        {label: 'Burundi', value: 'Burundi'},
        {label: 'Kambodscha', value: 'Cambodia'},
        {label: 'Kanada', value: 'Canada'},
        {label: 'Cabo Verde', value: 'Cape Verde'},
        {label: 'Karibische Niederlande', value: 'Caribbean Netherlands'},
        {label: 'Kaimaninseln', value: 'Cayman Islands'},
        {
          label: 'Zentralafrikanische Republik',
          value: 'Central African Republic',
        },
        {label: 'Tschad', value: 'Chad'},
        {label: 'Chile', value: 'Chile'},
        {label: 'China', value: 'China'},
        {label: 'Weihnachtsinsel', value: 'Christmas Island'},
        {label: 'Kokosinseln', value: 'Cocos (Keeling) Islands'},
        {label: 'Kolumbien', value: 'Colombia'},
        {label: 'Komoren', value: 'Comoros'},
        {label: 'Kongo-Brazzaville', value: 'Congo'},
        {
          label: 'Kongo-Kinshasa',
          value: 'Congo, The Democratic Republic Of The',
        },
        {label: 'Cookinseln', value: 'Cook Islands'},
        {label: 'Costa Rica', value: 'Costa Rica'},
        {label: 'Kroatien', value: 'Croatia'},
        {label: 'Kuba', value: 'Cuba'},
        {label: 'Curaçao', value: 'Curaçao'},
        {label: 'Zypern', value: 'Cyprus'},
        {label: 'Tschechien', value: 'Czech Republic'},
        {label: 'Côte d’Ivoire', value: "Côte d'Ivoire"},
        {label: 'Dänemark', value: 'Denmark'},
        {label: 'Dschibuti', value: 'Djibouti'},
        {label: 'Dominica', value: 'Dominica'},
        {label: 'Dominikanische Republik', value: 'Dominican Republic'},
        {label: 'Ecuador', value: 'Ecuador'},
        {label: 'Ägypten', value: 'Egypt'},
        {label: 'El Salvador', value: 'El Salvador'},
        {label: 'Äquatorialguinea', value: 'Equatorial Guinea'},
        {label: 'Eritrea', value: 'Eritrea'},
        {label: 'Estland', value: 'Estonia'},
        {label: 'Eswatini', value: 'Eswatini'},
        {label: 'Äthiopien', value: 'Ethiopia'},
        {label: 'Falklandinseln', value: 'Falkland Islands (Malvinas)'},
        {label: 'Färöer', value: 'Faroe Islands'},
        {label: 'Fidschi', value: 'Fiji'},
        {label: 'Finnland', value: 'Finland'},
        {label: 'Frankreich', value: 'France'},
        {label: 'Französisch-Guayana', value: 'French Guiana'},
        {label: 'Französisch-Polynesien', value: 'French Polynesia'},
        {
          label: 'Französische Süd- und Antarktisgebiete',
          value: 'French Southern Territories',
        },
        {label: 'Gabun', value: 'Gabon'},
        {label: 'Gambia', value: 'Gambia'},
        {label: 'Georgien', value: 'Georgia'},
        {label: 'Deutschland', value: 'Germany'},
        {label: 'Ghana', value: 'Ghana'},
        {label: 'Gibraltar', value: 'Gibraltar'},
        {label: 'Griechenland', value: 'Greece'},
        {label: 'Grönland', value: 'Greenland'},
        {label: 'Grenada', value: 'Grenada'},
        {label: 'Guadeloupe', value: 'Guadeloupe'},
        {label: 'Guatemala', value: 'Guatemala'},
        {label: 'Guernsey', value: 'Guernsey'},
        {label: 'Guinea', value: 'Guinea'},
        {label: 'Guinea-Bissau', value: 'Guinea Bissau'},
        {label: 'Guyana', value: 'Guyana'},
        {label: 'Haiti', value: 'Haiti'},
        {
          label: 'Heard und McDonaldinseln',
          value: 'Heard Island And Mcdonald Islands',
        },
        {label: 'Vatikanstadt', value: 'Holy See (Vatican City State)'},
        {label: 'Honduras', value: 'Honduras'},
        {label: 'Sonderverwaltungsregion Hongkong', value: 'Hong Kong'},
        {label: 'Ungarn', value: 'Hungary'},
        {label: 'Island', value: 'Iceland'},
        {label: 'Indien', value: 'India'},
        {label: 'Indonesien', value: 'Indonesia'},
        {label: 'Iran', value: 'Iran, Islamic Republic Of'},
        {label: 'Irak', value: 'Iraq'},
        {label: 'Irland', value: 'Ireland'},
        {label: 'Isle of Man', value: 'Isle Of Man'},
        {label: 'Israel', value: 'Israel'},
        {label: 'Italien', value: 'Italy'},
        {label: 'Jamaika', value: 'Jamaica'},
        {label: 'Japan', value: 'Japan'},
        {label: 'Jersey', value: 'Jersey'},
        {label: 'Jordanien', value: 'Jordan'},
        {label: 'Kasachstan', value: 'Kazakhstan'},
        {label: 'Kenia', value: 'Kenya'},
        {label: 'Kiribati', value: 'Kiribati'},
        {label: 'Nordkorea', value: "Korea, Democratic People's Republic Of"},
        {label: 'Kosovo', value: 'Kosovo'},
        {label: 'Kuwait', value: 'Kuwait'},
        {label: 'Kirgisistan', value: 'Kyrgyzstan'},
        {label: 'Laos', value: "Lao People's Democratic Republic"},
        {label: 'Lettland', value: 'Latvia'},
        {label: 'Libanon', value: 'Lebanon'},
        {label: 'Lesotho', value: 'Lesotho'},
        {label: 'Liberia', value: 'Liberia'},
        {label: 'Libyen', value: 'Libyan Arab Jamahiriya'},
        {label: 'Liechtenstein', value: 'Liechtenstein'},
        {label: 'Litauen', value: 'Lithuania'},
        {label: 'Luxemburg', value: 'Luxembourg'},
        {label: 'Sonderverwaltungsregion Macau', value: 'Macao'},
        {label: 'Madagaskar', value: 'Madagascar'},
        {label: 'Malawi', value: 'Malawi'},
        {label: 'Malaysia', value: 'Malaysia'},
        {label: 'Malediven', value: 'Maldives'},
        {label: 'Mali', value: 'Mali'},
        {label: 'Malta', value: 'Malta'},
        {label: 'Martinique', value: 'Martinique'},
        {label: 'Mauretanien', value: 'Mauritania'},
        {label: 'Mauritius', value: 'Mauritius'},
        {label: 'Mayotte', value: 'Mayotte'},
        {label: 'Mexiko', value: 'Mexico'},
        {label: 'Republik Moldau', value: 'Moldova, Republic of'},
        {label: 'Monaco', value: 'Monaco'},
        {label: 'Mongolei', value: 'Mongolia'},
        {label: 'Montenegro', value: 'Montenegro'},
        {label: 'Montserrat', value: 'Montserrat'},
        {label: 'Marokko', value: 'Morocco'},
        {label: 'Mosambik', value: 'Mozambique'},
        {label: 'Myanmar', value: 'Myanmar'},
        {label: 'Namibia', value: 'Namibia'},
        {label: 'Nauru', value: 'Nauru'},
        {label: 'Nepal', value: 'Nepal'},
        {label: 'Niederlande', value: 'Netherlands'},
        {label: 'Niederländische Antillen', value: 'Netherlands Antilles'},
        {label: 'Neukaledonien', value: 'New Caledonia'},
        {label: 'Neuseeland', value: 'New Zealand'},
        {label: 'Nicaragua', value: 'Nicaragua'},
        {label: 'Niger', value: 'Niger'},
        {label: 'Nigeria', value: 'Nigeria'},
        {label: 'Niue', value: 'Niue'},
        {label: 'Norfolkinsel', value: 'Norfolk Island'},
        {label: 'Nordmazedonien', value: 'North Macedonia'},
        {label: 'Norwegen', value: 'Norway'},
        {label: 'Oman', value: 'Oman'},
        {label: 'Pakistan', value: 'Pakistan'},
        {
          label: 'Palästinensische Autonomiegebiete',
          value: 'Palestinian Territory, Occupied',
        },
        {label: 'Panama', value: 'Panama'},
        {label: 'Papua-Neuguinea', value: 'Papua New Guinea'},
        {label: 'Paraguay', value: 'Paraguay'},
        {label: 'Peru', value: 'Peru'},
        {label: 'Philippinen', value: 'Philippines'},
        {label: 'Pitcairninseln', value: 'Pitcairn'},
        {label: 'Polen', value: 'Poland'},
        {label: 'Portugal', value: 'Portugal'},
        {label: 'Katar', value: 'Qatar'},
        {label: 'Kamerun', value: 'Republic of Cameroon'},
        {label: 'Réunion', value: 'Reunion'},
        {label: 'Rumänien', value: 'Romania'},
        {label: 'Russland', value: 'Russia'},
        {label: 'Ruanda', value: 'Rwanda'},
        {label: 'St. Barthélemy', value: 'Saint Barthélemy'},
        {label: 'St. Helena', value: 'Saint Helena'},
        {label: 'St. Kitts und Nevis', value: 'Saint Kitts And Nevis'},
        {label: 'St. Lucia', value: 'Saint Lucia'},
        {label: 'St. Martin', value: 'Saint Martin'},
        {label: 'St. Pierre und Miquelon', value: 'Saint Pierre And Miquelon'},
        {label: 'Samoa', value: 'Samoa'},
        {label: 'San Marino', value: 'San Marino'},
        {label: 'São Tomé und Príncipe', value: 'Sao Tome And Principe'},
        {label: 'Saudi-Arabien', value: 'Saudi Arabia'},
        {label: 'Senegal', value: 'Senegal'},
        {label: 'Serbien', value: 'Serbia'},
        {label: 'Seychellen', value: 'Seychelles'},
        {label: 'Sierra Leone', value: 'Sierra Leone'},
        {label: 'Singapur', value: 'Singapore'},
        {label: 'Sint Maarten', value: 'Sint Maarten'},
        {label: 'Slowakei', value: 'Slovakia'},
        {label: 'Slowenien', value: 'Slovenia'},
        {label: 'Salomonen', value: 'Solomon Islands'},
        {label: 'Somalia', value: 'Somalia'},
        {label: 'Südafrika', value: 'South Africa'},
        {
          label: 'Südgeorgien und die Südlichen Sandwichinseln',
          value: 'South Georgia And The South Sandwich Islands',
        },
        {label: 'Südkorea', value: 'South Korea'},
        {label: 'Südsudan', value: 'South Sudan'},
        {label: 'Spanien', value: 'Spain'},
        {label: 'Sri Lanka', value: 'Sri Lanka'},
        {label: 'St. Vincent und die Grenadinen', value: 'St. Vincent'},
        {label: 'Sudan', value: 'Sudan'},
        {label: 'Suriname', value: 'Suriname'},
        {label: 'Spitzbergen und Jan Mayen', value: 'Svalbard And Jan Mayen'},
        {label: 'Schweden', value: 'Sweden'},
        {label: 'Schweiz', value: 'Switzerland'},
        {label: 'Syrien', value: 'Syria'},
        {label: 'Taiwan', value: 'Taiwan'},
        {label: 'Tadschikistan', value: 'Tajikistan'},
        {label: 'Tansania', value: 'Tanzania, United Republic Of'},
        {label: 'Thailand', value: 'Thailand'},
        {label: 'Timor-Leste', value: 'Timor Leste'},
        {label: 'Togo', value: 'Togo'},
        {label: 'Tokelau', value: 'Tokelau'},
        {label: 'Tonga', value: 'Tonga'},
        {label: 'Trinidad und Tobago', value: 'Trinidad and Tobago'},
        {label: 'Tristan da Cunha', value: 'Tristan da Cunha'},
        {label: 'Tunesien', value: 'Tunisia'},
        {label: 'Türkei', value: 'Turkey'},
        {label: 'Turkmenistan', value: 'Turkmenistan'},
        {label: 'Turks- und Caicosinseln', value: 'Turks and Caicos Islands'},
        {label: 'Tuvalu', value: 'Tuvalu'},
        {label: 'Uganda', value: 'Uganda'},
        {label: 'Ukraine', value: 'Ukraine'},
        {label: 'Vereinigte Arabische Emirate', value: 'United Arab Emirates'},
        {label: 'Vereinigtes Königreich', value: 'United Kingdom'},
        {label: 'Vereinigte Staaten', value: 'United States'},
        {
          label: 'Amerikanische Überseeinseln',
          value: 'United States Minor Outlying Islands',
        },
        {label: 'Uruguay', value: 'Uruguay'},
        {label: 'Usbekistan', value: 'Uzbekistan'},
        {label: 'Vanuatu', value: 'Vanuatu'},
        {label: 'Venezuela', value: 'Venezuela'},
        {label: 'Vietnam', value: 'Vietnam'},
        {label: 'Britische Jungferninseln', value: 'Virgin Islands, British'},
        {label: 'Wallis und Futuna', value: 'Wallis And Futuna'},
        {label: 'Westsahara', value: 'Western Sahara'},
        {label: 'Jemen', value: 'Yemen'},
        {label: 'Sambia', value: 'Zambia'},
        {label: 'Simbabwe', value: 'Zimbabwe'},
      ];
    case 'es-ES':
      return [
        {label: 'Selecciona uno', value: ''},
        {label: 'Afganistán', value: 'Afghanistan'},
        {label: 'Islas Aland', value: 'Aland Islands'},
        {label: 'Albania', value: 'Albania'},
        {label: 'Argelia', value: 'Algeria'},
        {label: 'Andorra', value: 'Andorra'},
        {label: 'Angola', value: 'Angola'},
        {label: 'Anguila', value: 'Anguilla'},
        {label: 'Antigua y Barbuda', value: 'Antigua And Barbuda'},
        {label: 'Argentina', value: 'Argentina'},
        {label: 'Armenia', value: 'Armenia'},
        {label: 'Aruba', value: 'Aruba'},
        {label: 'Isla de la Ascensión', value: 'Ascension Island'},
        {label: 'Australia', value: 'Australia'},
        {label: 'Austria', value: 'Austria'},
        {label: 'Azerbaiyán', value: 'Azerbaijan'},
        {label: 'Bahamas', value: 'Bahamas'},
        {label: 'Baréin', value: 'Bahrain'},
        {label: 'Bangladés', value: 'Bangladesh'},
        {label: 'Barbados', value: 'Barbados'},
        {label: 'Bielorrusia', value: 'Belarus'},
        {label: 'Bélgica', value: 'Belgium'},
        {label: 'Belice', value: 'Belize'},
        {label: 'Benín', value: 'Benin'},
        {label: 'Bermudas', value: 'Bermuda'},
        {label: 'Bután', value: 'Bhutan'},
        {label: 'Bolivia', value: 'Bolivia'},
        {label: 'Bosnia y Herzegovina', value: 'Bosnia And Herzegovina'},
        {label: 'Botsuana', value: 'Botswana'},
        {label: 'Isla Bouvet', value: 'Bouvet Island'},
        {label: 'Brasil', value: 'Brazil'},
        {
          label: 'Territorio Británico del Océano Índico',
          value: 'British Indian Ocean Territory',
        },
        {label: 'Brunéi', value: 'Brunei'},
        {label: 'Bulgaria', value: 'Bulgaria'},
        {label: 'Burkina Faso', value: 'Burkina Faso'},
        {label: 'Burundi', value: 'Burundi'},
        {label: 'Camboya', value: 'Cambodia'},
        {label: 'Canadá', value: 'Canada'},
        {label: 'Cabo Verde', value: 'Cape Verde'},
        {label: 'Caribe neerlandés', value: 'Caribbean Netherlands'},
        {label: 'Islas Caimán', value: 'Cayman Islands'},
        {label: 'República Centroafricana', value: 'Central African Republic'},
        {label: 'Chad', value: 'Chad'},
        {label: 'Chile', value: 'Chile'},
        {label: 'China', value: 'China'},
        {label: 'Isla de Navidad', value: 'Christmas Island'},
        {label: 'Islas Cocos', value: 'Cocos (Keeling) Islands'},
        {label: 'Colombia', value: 'Colombia'},
        {label: 'Comoras', value: 'Comoros'},
        {label: 'Congo', value: 'Congo'},
        {
          label: 'República Democrática del Congo',
          value: 'Congo, The Democratic Republic Of The',
        },
        {label: 'Islas Cook', value: 'Cook Islands'},
        {label: 'Costa Rica', value: 'Costa Rica'},
        {label: 'Croacia', value: 'Croatia'},
        {label: 'Cuba', value: 'Cuba'},
        {label: 'Curazao', value: 'Curaçao'},
        {label: 'Chipre', value: 'Cyprus'},
        {label: 'Chequia', value: 'Czech Republic'},
        {label: 'Côte d’Ivoire', value: "Côte d'Ivoire"},
        {label: 'Dinamarca', value: 'Denmark'},
        {label: 'Yibuti', value: 'Djibouti'},
        {label: 'Dominica', value: 'Dominica'},
        {label: 'República Dominicana', value: 'Dominican Republic'},
        {label: 'Ecuador', value: 'Ecuador'},
        {label: 'Egipto', value: 'Egypt'},
        {label: 'El Salvador', value: 'El Salvador'},
        {label: 'Guinea Ecuatorial', value: 'Equatorial Guinea'},
        {label: 'Eritrea', value: 'Eritrea'},
        {label: 'Estonia', value: 'Estonia'},
        {label: 'Esuatini', value: 'Eswatini'},
        {label: 'Etiopía', value: 'Ethiopia'},
        {label: 'Islas Malvinas', value: 'Falkland Islands (Malvinas)'},
        {label: 'Islas Feroe', value: 'Faroe Islands'},
        {label: 'Fiyi', value: 'Fiji'},
        {label: 'Finlandia', value: 'Finland'},
        {label: 'Francia', value: 'France'},
        {label: 'Guayana Francesa', value: 'French Guiana'},
        {label: 'Polinesia Francesa', value: 'French Polynesia'},
        {
          label: 'Territorios Australes Franceses',
          value: 'French Southern Territories',
        },
        {label: 'Gabón', value: 'Gabon'},
        {label: 'Gambia', value: 'Gambia'},
        {label: 'Georgia', value: 'Georgia'},
        {label: 'Alemania', value: 'Germany'},
        {label: 'Ghana', value: 'Ghana'},
        {label: 'Gibraltar', value: 'Gibraltar'},
        {label: 'Grecia', value: 'Greece'},
        {label: 'Groenlandia', value: 'Greenland'},
        {label: 'Granada', value: 'Grenada'},
        {label: 'Guadalupe', value: 'Guadeloupe'},
        {label: 'Guatemala', value: 'Guatemala'},
        {label: 'Guernesey', value: 'Guernsey'},
        {label: 'Guinea', value: 'Guinea'},
        {label: 'Guinea-Bisáu', value: 'Guinea Bissau'},
        {label: 'Guyana', value: 'Guyana'},
        {label: 'Haití', value: 'Haiti'},
        {
          label: 'Islas Heard y McDonald',
          value: 'Heard Island And Mcdonald Islands',
        },
        {label: 'Ciudad del Vaticano', value: 'Holy See (Vatican City State)'},
        {label: 'Honduras', value: 'Honduras'},
        {label: 'RAE de Hong Kong (China)', value: 'Hong Kong'},
        {label: 'Hungría', value: 'Hungary'},
        {label: 'Islandia', value: 'Iceland'},
        {label: 'India', value: 'India'},
        {label: 'Indonesia', value: 'Indonesia'},
        {label: 'Irán', value: 'Iran, Islamic Republic Of'},
        {label: 'Irak', value: 'Iraq'},
        {label: 'Irlanda', value: 'Ireland'},
        {label: 'Isla de Man', value: 'Isle Of Man'},
        {label: 'Israel', value: 'Israel'},
        {label: 'Italia', value: 'Italy'},
        {label: 'Jamaica', value: 'Jamaica'},
        {label: 'Japón', value: 'Japan'},
        {label: 'Jersey', value: 'Jersey'},
        {label: 'Jordania', value: 'Jordan'},
        {label: 'Kazajistán', value: 'Kazakhstan'},
        {label: 'Kenia', value: 'Kenya'},
        {label: 'Kiribati', value: 'Kiribati'},
        {
          label: 'Corea del Norte',
          value: "Korea, Democratic People's Republic Of",
        },
        {label: 'Kosovo', value: 'Kosovo'},
        {label: 'Kuwait', value: 'Kuwait'},
        {label: 'Kirguistán', value: 'Kyrgyzstan'},
        {label: 'Laos', value: "Lao People's Democratic Republic"},
        {label: 'Letonia', value: 'Latvia'},
        {label: 'Líbano', value: 'Lebanon'},
        {label: 'Lesoto', value: 'Lesotho'},
        {label: 'Liberia', value: 'Liberia'},
        {label: 'Libia', value: 'Libyan Arab Jamahiriya'},
        {label: 'Liechtenstein', value: 'Liechtenstein'},
        {label: 'Lituania', value: 'Lithuania'},
        {label: 'Luxemburgo', value: 'Luxembourg'},
        {label: 'RAE de Macao (China)', value: 'Macao'},
        {label: 'Madagascar', value: 'Madagascar'},
        {label: 'Malaui', value: 'Malawi'},
        {label: 'Malasia', value: 'Malaysia'},
        {label: 'Maldivas', value: 'Maldives'},
        {label: 'Mali', value: 'Mali'},
        {label: 'Malta', value: 'Malta'},
        {label: 'Martinica', value: 'Martinique'},
        {label: 'Mauritania', value: 'Mauritania'},
        {label: 'Mauricio', value: 'Mauritius'},
        {label: 'Mayotte', value: 'Mayotte'},
        {label: 'México', value: 'Mexico'},
        {label: 'Moldavia', value: 'Moldova, Republic of'},
        {label: 'Mónaco', value: 'Monaco'},
        {label: 'Mongolia', value: 'Mongolia'},
        {label: 'Montenegro', value: 'Montenegro'},
        {label: 'Montserrat', value: 'Montserrat'},
        {label: 'Marruecos', value: 'Morocco'},
        {label: 'Mozambique', value: 'Mozambique'},
        {label: 'Myanmar (Birmania)', value: 'Myanmar'},
        {label: 'Namibia', value: 'Namibia'},
        {label: 'Nauru', value: 'Nauru'},
        {label: 'Nepal', value: 'Nepal'},
        {label: 'Países Bajos', value: 'Netherlands'},
        {label: 'Antillas Holandesas', value: 'Netherlands Antilles'},
        {label: 'Nueva Caledonia', value: 'New Caledonia'},
        {label: 'Nueva Zelanda', value: 'New Zealand'},
        {label: 'Nicaragua', value: 'Nicaragua'},
        {label: 'Níger', value: 'Niger'},
        {label: 'Nigeria', value: 'Nigeria'},
        {label: 'Niue', value: 'Niue'},
        {label: 'Isla Norfolk', value: 'Norfolk Island'},
        {label: 'Macedonia del Norte', value: 'North Macedonia'},
        {label: 'Noruega', value: 'Norway'},
        {label: 'Omán', value: 'Oman'},
        {label: 'Pakistán', value: 'Pakistan'},
        {
          label: 'Territorios Palestinos',
          value: 'Palestinian Territory, Occupied',
        },
        {label: 'Panamá', value: 'Panama'},
        {label: 'Papúa Nueva Guinea', value: 'Papua New Guinea'},
        {label: 'Paraguay', value: 'Paraguay'},
        {label: 'Perú', value: 'Peru'},
        {label: 'Filipinas', value: 'Philippines'},
        {label: 'Islas Pitcairn', value: 'Pitcairn'},
        {label: 'Polonia', value: 'Poland'},
        {label: 'Portugal', value: 'Portugal'},
        {label: 'Catar', value: 'Qatar'},
        {label: 'Camerún', value: 'Republic of Cameroon'},
        {label: 'Reunión', value: 'Reunion'},
        {label: 'Rumanía', value: 'Romania'},
        {label: 'Rusia', value: 'Russia'},
        {label: 'Ruanda', value: 'Rwanda'},
        {label: 'San Bartolomé', value: 'Saint Barthélemy'},
        {label: 'Santa Elena', value: 'Saint Helena'},
        {label: 'San Cristóbal y Nieves', value: 'Saint Kitts And Nevis'},
        {label: 'Santa Lucía', value: 'Saint Lucia'},
        {label: 'San Martín', value: 'Saint Martin'},
        {label: 'San Pedro y Miquelón', value: 'Saint Pierre And Miquelon'},
        {label: 'Samoa', value: 'Samoa'},
        {label: 'San Marino', value: 'San Marino'},
        {label: 'Santo Tomé y Príncipe', value: 'Sao Tome And Principe'},
        {label: 'Arabia Saudí', value: 'Saudi Arabia'},
        {label: 'Senegal', value: 'Senegal'},
        {label: 'Serbia', value: 'Serbia'},
        {label: 'Seychelles', value: 'Seychelles'},
        {label: 'Sierra Leona', value: 'Sierra Leone'},
        {label: 'Singapur', value: 'Singapore'},
        {label: 'Sint Maarten', value: 'Sint Maarten'},
        {label: 'Eslovaquia', value: 'Slovakia'},
        {label: 'Eslovenia', value: 'Slovenia'},
        {label: 'Islas Salomón', value: 'Solomon Islands'},
        {label: 'Somalia', value: 'Somalia'},
        {label: 'Sudáfrica', value: 'South Africa'},
        {
          label: 'Islas Georgia del Sur y Sandwich del Sur',
          value: 'South Georgia And The South Sandwich Islands',
        },
        {label: 'Corea del Sur', value: 'South Korea'},
        {label: 'Sudán del Sur', value: 'South Sudan'},
        {label: 'España', value: 'Spain'},
        {label: 'Sri Lanka', value: 'Sri Lanka'},
        {label: 'San Vicente y las Granadinas', value: 'St. Vincent'},
        {label: 'Sudán', value: 'Sudan'},
        {label: 'Surinam', value: 'Suriname'},
        {label: 'Svalbard y Jan Mayen', value: 'Svalbard And Jan Mayen'},
        {label: 'Suecia', value: 'Sweden'},
        {label: 'Suiza', value: 'Switzerland'},
        {label: 'Siria', value: 'Syria'},
        {label: 'Taiwán', value: 'Taiwan'},
        {label: 'Tayikistán', value: 'Tajikistan'},
        {label: 'Tanzania', value: 'Tanzania, United Republic Of'},
        {label: 'Tailandia', value: 'Thailand'},
        {label: 'Timor-Leste', value: 'Timor Leste'},
        {label: 'Togo', value: 'Togo'},
        {label: 'Tokelau', value: 'Tokelau'},
        {label: 'Tonga', value: 'Tonga'},
        {label: 'Trinidad y Tobago', value: 'Trinidad and Tobago'},
        {label: 'Tristán de Acuña', value: 'Tristan da Cunha'},
        {label: 'Túnez', value: 'Tunisia'},
        {label: 'Turquía', value: 'Turkey'},
        {label: 'Turkmenistán', value: 'Turkmenistan'},
        {label: 'Islas Turcas y Caicos', value: 'Turks and Caicos Islands'},
        {label: 'Tuvalu', value: 'Tuvalu'},
        {label: 'Uganda', value: 'Uganda'},
        {label: 'Ucrania', value: 'Ukraine'},
        {label: 'Emiratos Árabes Unidos', value: 'United Arab Emirates'},
        {label: 'Reino Unido', value: 'United Kingdom'},
        {label: 'Estados Unidos', value: 'United States'},
        {
          label: 'Islas menores alejadas de EE. UU.',
          value: 'United States Minor Outlying Islands',
        },
        {label: 'Uruguay', value: 'Uruguay'},
        {label: 'Uzbekistán', value: 'Uzbekistan'},
        {label: 'Vanuatu', value: 'Vanuatu'},
        {label: 'Venezuela', value: 'Venezuela'},
        {label: 'Vietnam', value: 'Vietnam'},
        {label: 'Islas Vírgenes Británicas', value: 'Virgin Islands, British'},
        {label: 'Wallis y Futuna', value: 'Wallis And Futuna'},
        {label: 'Sáhara Occidental', value: 'Western Sahara'},
        {label: 'Yemen', value: 'Yemen'},
        {label: 'Zambia', value: 'Zambia'},
        {label: 'Zimbabue', value: 'Zimbabwe'},
      ];
    case 'fr':
      return [
        {label: 'Sélectionnez une option', value: ''},
        {label: 'Afghanistan', value: 'Afghanistan'},
        {label: 'Îles Åland', value: 'Aland Islands'},
        {label: 'Albanie', value: 'Albania'},
        {label: 'Algérie', value: 'Algeria'},
        {label: 'Andorre', value: 'Andorra'},
        {label: 'Angola', value: 'Angola'},
        {label: 'Anguilla', value: 'Anguilla'},
        {label: 'Antigua-et-Barbuda', value: 'Antigua And Barbuda'},
        {label: 'Argentine', value: 'Argentina'},
        {label: 'Arménie', value: 'Armenia'},
        {label: 'Aruba', value: 'Aruba'},
        {label: 'Île de l’Ascension', value: 'Ascension Island'},
        {label: 'Australie', value: 'Australia'},
        {label: 'Autriche', value: 'Austria'},
        {label: 'Azerbaïdjan', value: 'Azerbaijan'},
        {label: 'Bahamas', value: 'Bahamas'},
        {label: 'Bahreïn', value: 'Bahrain'},
        {label: 'Bangladesh', value: 'Bangladesh'},
        {label: 'Barbade', value: 'Barbados'},
        {label: 'Biélorussie', value: 'Belarus'},
        {label: 'Belgique', value: 'Belgium'},
        {label: 'Belize', value: 'Belize'},
        {label: 'Bénin', value: 'Benin'},
        {label: 'Bermudes', value: 'Bermuda'},
        {label: 'Bhoutan', value: 'Bhutan'},
        {label: 'Bolivie', value: 'Bolivia'},
        {label: 'Bosnie-Herzégovine', value: 'Bosnia And Herzegovina'},
        {label: 'Botswana', value: 'Botswana'},
        {label: 'Île Bouvet', value: 'Bouvet Island'},
        {label: 'Brésil', value: 'Brazil'},
        {
          label: 'Territoire britannique de l’océan Indien',
          value: 'British Indian Ocean Territory',
        },
        {label: 'Brunei', value: 'Brunei'},
        {label: 'Bulgarie', value: 'Bulgaria'},
        {label: 'Burkina Faso', value: 'Burkina Faso'},
        {label: 'Burundi', value: 'Burundi'},
        {label: 'Cambodge', value: 'Cambodia'},
        {label: 'Canada', value: 'Canada'},
        {label: 'Cap-Vert', value: 'Cape Verde'},
        {label: 'Pays-Bas caribéens', value: 'Caribbean Netherlands'},
        {label: 'Îles Caïmans', value: 'Cayman Islands'},
        {label: 'République centrafricaine', value: 'Central African Republic'},
        {label: 'Tchad', value: 'Chad'},
        {label: 'Chili', value: 'Chile'},
        {label: 'Chine', value: 'China'},
        {label: 'Île Christmas', value: 'Christmas Island'},
        {label: 'Îles Cocos', value: 'Cocos (Keeling) Islands'},
        {label: 'Colombie', value: 'Colombia'},
        {label: 'Comores', value: 'Comoros'},
        {label: 'Congo-Brazzaville', value: 'Congo'},
        {
          label: 'Congo-Kinshasa',
          value: 'Congo, The Democratic Republic Of The',
        },
        {label: 'Îles Cook', value: 'Cook Islands'},
        {label: 'Costa Rica', value: 'Costa Rica'},
        {label: 'Croatie', value: 'Croatia'},
        {label: 'Cuba', value: 'Cuba'},
        {label: 'Curaçao', value: 'Curaçao'},
        {label: 'Chypre', value: 'Cyprus'},
        {label: 'Tchéquie', value: 'Czech Republic'},
        {label: 'Côte d’Ivoire', value: "Côte d'Ivoire"},
        {label: 'Danemark', value: 'Denmark'},
        {label: 'Djibouti', value: 'Djibouti'},
        {label: 'Dominique', value: 'Dominica'},
        {label: 'République dominicaine', value: 'Dominican Republic'},
        {label: 'Équateur', value: 'Ecuador'},
        {label: 'Égypte', value: 'Egypt'},
        {label: 'Salvador', value: 'El Salvador'},
        {label: 'Guinée équatoriale', value: 'Equatorial Guinea'},
        {label: 'Érythrée', value: 'Eritrea'},
        {label: 'Estonie', value: 'Estonia'},
        {label: 'Eswatini', value: 'Eswatini'},
        {label: 'Éthiopie', value: 'Ethiopia'},
        {label: 'Îles Malouines', value: 'Falkland Islands (Malvinas)'},
        {label: 'Îles Féroé', value: 'Faroe Islands'},
        {label: 'Fidji', value: 'Fiji'},
        {label: 'Finlande', value: 'Finland'},
        {label: 'France', value: 'France'},
        {label: 'Guyane française', value: 'French Guiana'},
        {label: 'Polynésie française', value: 'French Polynesia'},
        {
          label: 'Terres australes françaises',
          value: 'French Southern Territories',
        },
        {label: 'Gabon', value: 'Gabon'},
        {label: 'Gambie', value: 'Gambia'},
        {label: 'Géorgie', value: 'Georgia'},
        {label: 'Allemagne', value: 'Germany'},
        {label: 'Ghana', value: 'Ghana'},
        {label: 'Gibraltar', value: 'Gibraltar'},
        {label: 'Grèce', value: 'Greece'},
        {label: 'Groenland', value: 'Greenland'},
        {label: 'Grenade', value: 'Grenada'},
        {label: 'Guadeloupe', value: 'Guadeloupe'},
        {label: 'Guatemala', value: 'Guatemala'},
        {label: 'Guernesey', value: 'Guernsey'},
        {label: 'Guinée', value: 'Guinea'},
        {label: 'Guinée-Bissau', value: 'Guinea Bissau'},
        {label: 'Guyana', value: 'Guyana'},
        {label: 'Haïti', value: 'Haiti'},
        {
          label: 'Îles Heard-et-MacDonald',
          value: 'Heard Island And Mcdonald Islands',
        },
        {
          label: 'État de la Cité du Vatican',
          value: 'Holy See (Vatican City State)',
        },
        {label: 'Honduras', value: 'Honduras'},
        {label: 'R.A.S. chinoise de Hong Kong', value: 'Hong Kong'},
        {label: 'Hongrie', value: 'Hungary'},
        {label: 'Islande', value: 'Iceland'},
        {label: 'Inde', value: 'India'},
        {label: 'Indonésie', value: 'Indonesia'},
        {label: 'Iran', value: 'Iran, Islamic Republic Of'},
        {label: 'Irak', value: 'Iraq'},
        {label: 'Irlande', value: 'Ireland'},
        {label: 'Île de Man', value: 'Isle Of Man'},
        {label: 'Israël', value: 'Israel'},
        {label: 'Italie', value: 'Italy'},
        {label: 'Jamaïque', value: 'Jamaica'},
        {label: 'Japon', value: 'Japan'},
        {label: 'Jersey', value: 'Jersey'},
        {label: 'Jordanie', value: 'Jordan'},
        {label: 'Kazakhstan', value: 'Kazakhstan'},
        {label: 'Kenya', value: 'Kenya'},
        {label: 'Kiribati', value: 'Kiribati'},
        {
          label: 'Corée du Nord',
          value: "Korea, Democratic People's Republic Of",
        },
        {label: 'Kosovo', value: 'Kosovo'},
        {label: 'Koweït', value: 'Kuwait'},
        {label: 'Kirghizstan', value: 'Kyrgyzstan'},
        {label: 'Laos', value: "Lao People's Democratic Republic"},
        {label: 'Lettonie', value: 'Latvia'},
        {label: 'Liban', value: 'Lebanon'},
        {label: 'Lesotho', value: 'Lesotho'},
        {label: 'Liberia', value: 'Liberia'},
        {label: 'Libye', value: 'Libyan Arab Jamahiriya'},
        {label: 'Liechtenstein', value: 'Liechtenstein'},
        {label: 'Lituanie', value: 'Lithuania'},
        {label: 'Luxembourg', value: 'Luxembourg'},
        {label: 'R.A.S. chinoise de Macao', value: 'Macao'},
        {label: 'Madagascar', value: 'Madagascar'},
        {label: 'Malawi', value: 'Malawi'},
        {label: 'Malaisie', value: 'Malaysia'},
        {label: 'Maldives', value: 'Maldives'},
        {label: 'Mali', value: 'Mali'},
        {label: 'Malte', value: 'Malta'},
        {label: 'Martinique', value: 'Martinique'},
        {label: 'Mauritanie', value: 'Mauritania'},
        {label: 'Maurice', value: 'Mauritius'},
        {label: 'Mayotte', value: 'Mayotte'},
        {label: 'Mexique', value: 'Mexico'},
        {label: 'Moldavie', value: 'Moldova, Republic of'},
        {label: 'Monaco', value: 'Monaco'},
        {label: 'Mongolie', value: 'Mongolia'},
        {label: 'Monténégro', value: 'Montenegro'},
        {label: 'Montserrat', value: 'Montserrat'},
        {label: 'Maroc', value: 'Morocco'},
        {label: 'Mozambique', value: 'Mozambique'},
        {label: 'Myanmar (Birmanie)', value: 'Myanmar'},
        {label: 'Namibie', value: 'Namibia'},
        {label: 'Nauru', value: 'Nauru'},
        {label: 'Népal', value: 'Nepal'},
        {label: 'Pays-Bas', value: 'Netherlands'},
        {label: 'Antilles néerlandaises', value: 'Netherlands Antilles'},
        {label: 'Nouvelle-Calédonie', value: 'New Caledonia'},
        {label: 'Nouvelle-Zélande', value: 'New Zealand'},
        {label: 'Nicaragua', value: 'Nicaragua'},
        {label: 'Niger', value: 'Niger'},
        {label: 'Nigeria', value: 'Nigeria'},
        {label: 'Niue', value: 'Niue'},
        {label: 'Île Norfolk', value: 'Norfolk Island'},
        {label: 'Macédoine du Nord', value: 'North Macedonia'},
        {label: 'Norvège', value: 'Norway'},
        {label: 'Oman', value: 'Oman'},
        {label: 'Pakistan', value: 'Pakistan'},
        {
          label: 'Territoires palestiniens',
          value: 'Palestinian Territory, Occupied',
        },
        {label: 'Panama', value: 'Panama'},
        {label: 'Papouasie-Nouvelle-Guinée', value: 'Papua New Guinea'},
        {label: 'Paraguay', value: 'Paraguay'},
        {label: 'Pérou', value: 'Peru'},
        {label: 'Philippines', value: 'Philippines'},
        {label: 'Îles Pitcairn', value: 'Pitcairn'},
        {label: 'Pologne', value: 'Poland'},
        {label: 'Portugal', value: 'Portugal'},
        {label: 'Qatar', value: 'Qatar'},
        {label: 'Cameroun', value: 'Republic of Cameroon'},
        {label: 'La Réunion', value: 'Reunion'},
        {label: 'Roumanie', value: 'Romania'},
        {label: 'Russie', value: 'Russia'},
        {label: 'Rwanda', value: 'Rwanda'},
        {label: 'Saint-Barthélemy', value: 'Saint Barthélemy'},
        {label: 'Sainte-Hélène', value: 'Saint Helena'},
        {label: 'Saint-Christophe-et-Niévès', value: 'Saint Kitts And Nevis'},
        {label: 'Sainte-Lucie', value: 'Saint Lucia'},
        {label: 'Saint-Martin', value: 'Saint Martin'},
        {label: 'Saint-Pierre-et-Miquelon', value: 'Saint Pierre And Miquelon'},
        {label: 'Samoa', value: 'Samoa'},
        {label: 'Saint-Marin', value: 'San Marino'},
        {label: 'Sao Tomé-et-Principe', value: 'Sao Tome And Principe'},
        {label: 'Arabie saoudite', value: 'Saudi Arabia'},
        {label: 'Sénégal', value: 'Senegal'},
        {label: 'Serbie', value: 'Serbia'},
        {label: 'Seychelles', value: 'Seychelles'},
        {label: 'Sierra Leone', value: 'Sierra Leone'},
        {label: 'Singapour', value: 'Singapore'},
        {label: 'Saint-Martin (partie néerlandaise)', value: 'Sint Maarten'},
        {label: 'Slovaquie', value: 'Slovakia'},
        {label: 'Slovénie', value: 'Slovenia'},
        {label: 'Îles Salomon', value: 'Solomon Islands'},
        {label: 'Somalie', value: 'Somalia'},
        {label: 'Afrique du Sud', value: 'South Africa'},
        {
          label: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
          value: 'South Georgia And The South Sandwich Islands',
        },
        {label: 'Corée du Sud', value: 'South Korea'},
        {label: 'Soudan du Sud', value: 'South Sudan'},
        {label: 'Espagne', value: 'Spain'},
        {label: 'Sri Lanka', value: 'Sri Lanka'},
        {label: 'Saint-Vincent-et-les Grenadines', value: 'St. Vincent'},
        {label: 'Soudan', value: 'Sudan'},
        {label: 'Suriname', value: 'Suriname'},
        {label: 'Svalbard et Jan Mayen', value: 'Svalbard And Jan Mayen'},
        {label: 'Suède', value: 'Sweden'},
        {label: 'Suisse', value: 'Switzerland'},
        {label: 'Syrie', value: 'Syria'},
        {label: 'Taïwan', value: 'Taiwan'},
        {label: 'Tadjikistan', value: 'Tajikistan'},
        {label: 'Tanzanie', value: 'Tanzania, United Republic Of'},
        {label: 'Thaïlande', value: 'Thailand'},
        {label: 'Timor oriental', value: 'Timor Leste'},
        {label: 'Togo', value: 'Togo'},
        {label: 'Tokelau', value: 'Tokelau'},
        {label: 'Tonga', value: 'Tonga'},
        {label: 'Trinité-et-Tobago', value: 'Trinidad and Tobago'},
        {label: 'Tristan da Cunha', value: 'Tristan da Cunha'},
        {label: 'Tunisie', value: 'Tunisia'},
        {label: 'Turquie', value: 'Turkey'},
        {label: 'Turkménistan', value: 'Turkmenistan'},
        {label: 'Îles Turques-et-Caïques', value: 'Turks and Caicos Islands'},
        {label: 'Tuvalu', value: 'Tuvalu'},
        {label: 'Ouganda', value: 'Uganda'},
        {label: 'Ukraine', value: 'Ukraine'},
        {label: 'Émirats arabes unis', value: 'United Arab Emirates'},
        {label: 'Royaume-Uni', value: 'United Kingdom'},
        {label: 'États-Unis', value: 'United States'},
        {
          label: 'Îles mineures éloignées des États-Unis',
          value: 'United States Minor Outlying Islands',
        },
        {label: 'Uruguay', value: 'Uruguay'},
        {label: 'Ouzbékistan', value: 'Uzbekistan'},
        {label: 'Vanuatu', value: 'Vanuatu'},
        {label: 'Venezuela', value: 'Venezuela'},
        {label: 'Viêt Nam', value: 'Vietnam'},
        {label: 'Îles Vierges britanniques', value: 'Virgin Islands, British'},
        {label: 'Wallis-et-Futuna', value: 'Wallis And Futuna'},
        {label: 'Sahara occidental', value: 'Western Sahara'},
        {label: 'Yémen', value: 'Yemen'},
        {label: 'Zambie', value: 'Zambia'},
        {label: 'Zimbabwe', value: 'Zimbabwe'},
      ];
    case 'it':
      return [
        {label: 'Seleziona un’opzione', value: ''},
        {label: 'Afghanistan', value: 'Afghanistan'},
        {label: 'Isole Åland', value: 'Aland Islands'},
        {label: 'Albania', value: 'Albania'},
        {label: 'Algeria', value: 'Algeria'},
        {label: 'Andorra', value: 'Andorra'},
        {label: 'Angola', value: 'Angola'},
        {label: 'Anguilla', value: 'Anguilla'},
        {label: 'Antigua e Barbuda', value: 'Antigua And Barbuda'},
        {label: 'Argentina', value: 'Argentina'},
        {label: 'Armenia', value: 'Armenia'},
        {label: 'Aruba', value: 'Aruba'},
        {label: 'Isola Ascensione', value: 'Ascension Island'},
        {label: 'Australia', value: 'Australia'},
        {label: 'Austria', value: 'Austria'},
        {label: 'Azerbaigian', value: 'Azerbaijan'},
        {label: 'Bahamas', value: 'Bahamas'},
        {label: 'Bahrein', value: 'Bahrain'},
        {label: 'Bangladesh', value: 'Bangladesh'},
        {label: 'Barbados', value: 'Barbados'},
        {label: 'Bielorussia', value: 'Belarus'},
        {label: 'Belgio', value: 'Belgium'},
        {label: 'Belize', value: 'Belize'},
        {label: 'Benin', value: 'Benin'},
        {label: 'Bermuda', value: 'Bermuda'},
        {label: 'Bhutan', value: 'Bhutan'},
        {label: 'Bolivia', value: 'Bolivia'},
        {label: 'Bosnia ed Erzegovina', value: 'Bosnia And Herzegovina'},
        {label: 'Botswana', value: 'Botswana'},
        {label: 'Isola Bouvet', value: 'Bouvet Island'},
        {label: 'Brasile', value: 'Brazil'},
        {
          label: 'Territorio britannico dell’Oceano Indiano',
          value: 'British Indian Ocean Territory',
        },
        {label: 'Brunei', value: 'Brunei'},
        {label: 'Bulgaria', value: 'Bulgaria'},
        {label: 'Burkina Faso', value: 'Burkina Faso'},
        {label: 'Burundi', value: 'Burundi'},
        {label: 'Cambogia', value: 'Cambodia'},
        {label: 'Canada', value: 'Canada'},
        {label: 'Capo Verde', value: 'Cape Verde'},
        {label: 'Caraibi olandesi', value: 'Caribbean Netherlands'},
        {label: 'Isole Cayman', value: 'Cayman Islands'},
        {label: 'Repubblica Centrafricana', value: 'Central African Republic'},
        {label: 'Ciad', value: 'Chad'},
        {label: 'Cile', value: 'Chile'},
        {label: 'Cina', value: 'China'},
        {label: 'Isola Christmas', value: 'Christmas Island'},
        {label: 'Isole Cocos (Keeling)', value: 'Cocos (Keeling) Islands'},
        {label: 'Colombia', value: 'Colombia'},
        {label: 'Comore', value: 'Comoros'},
        {label: 'Congo-Brazzaville', value: 'Congo'},
        {
          label: 'Congo - Kinshasa',
          value: 'Congo, The Democratic Republic Of The',
        },
        {label: 'Isole Cook', value: 'Cook Islands'},
        {label: 'Costa Rica', value: 'Costa Rica'},
        {label: 'Croazia', value: 'Croatia'},
        {label: 'Cuba', value: 'Cuba'},
        {label: 'Curaçao', value: 'Curaçao'},
        {label: 'Cipro', value: 'Cyprus'},
        {label: 'Cechia', value: 'Czech Republic'},
        {label: 'Costa d’Avorio', value: "Côte d'Ivoire"},
        {label: 'Danimarca', value: 'Denmark'},
        {label: 'Gibuti', value: 'Djibouti'},
        {label: 'Dominica', value: 'Dominica'},
        {label: 'Repubblica Dominicana', value: 'Dominican Republic'},
        {label: 'Ecuador', value: 'Ecuador'},
        {label: 'Egitto', value: 'Egypt'},
        {label: 'El Salvador', value: 'El Salvador'},
        {label: 'Guinea Equatoriale', value: 'Equatorial Guinea'},
        {label: 'Eritrea', value: 'Eritrea'},
        {label: 'Estonia', value: 'Estonia'},
        {label: 'Swaziland', value: 'Eswatini'},
        {label: 'Etiopia', value: 'Ethiopia'},
        {label: 'Isole Falkland', value: 'Falkland Islands (Malvinas)'},
        {label: 'Isole Fær Øer', value: 'Faroe Islands'},
        {label: 'Figi', value: 'Fiji'},
        {label: 'Finlandia', value: 'Finland'},
        {label: 'Francia', value: 'France'},
        {label: 'Guyana francese', value: 'French Guiana'},
        {label: 'Polinesia francese', value: 'French Polynesia'},
        {
          label: 'Terre australi francesi',
          value: 'French Southern Territories',
        },
        {label: 'Gabon', value: 'Gabon'},
        {label: 'Gambia', value: 'Gambia'},
        {label: 'Georgia', value: 'Georgia'},
        {label: 'Germania', value: 'Germany'},
        {label: 'Ghana', value: 'Ghana'},
        {label: 'Gibilterra', value: 'Gibraltar'},
        {label: 'Grecia', value: 'Greece'},
        {label: 'Groenlandia', value: 'Greenland'},
        {label: 'Grenada', value: 'Grenada'},
        {label: 'Guadalupa', value: 'Guadeloupe'},
        {label: 'Guatemala', value: 'Guatemala'},
        {label: 'Guernsey', value: 'Guernsey'},
        {label: 'Guinea', value: 'Guinea'},
        {label: 'Guinea-Bissau', value: 'Guinea Bissau'},
        {label: 'Guyana', value: 'Guyana'},
        {label: 'Haiti', value: 'Haiti'},
        {
          label: 'Isole Heard e McDonald',
          value: 'Heard Island And Mcdonald Islands',
        },
        {label: 'Città del Vaticano', value: 'Holy See (Vatican City State)'},
        {label: 'Honduras', value: 'Honduras'},
        {label: 'RAS di Hong Kong', value: 'Hong Kong'},
        {label: 'Ungheria', value: 'Hungary'},
        {label: 'Islanda', value: 'Iceland'},
        {label: 'India', value: 'India'},
        {label: 'Indonesia', value: 'Indonesia'},
        {label: 'Iran', value: 'Iran, Islamic Republic Of'},
        {label: 'Iraq', value: 'Iraq'},
        {label: 'Irlanda', value: 'Ireland'},
        {label: 'Isola di Man', value: 'Isle Of Man'},
        {label: 'Israele', value: 'Israel'},
        {label: 'Italia', value: 'Italy'},
        {label: 'Giamaica', value: 'Jamaica'},
        {label: 'Giappone', value: 'Japan'},
        {label: 'Jersey', value: 'Jersey'},
        {label: 'Giordania', value: 'Jordan'},
        {label: 'Kazakistan', value: 'Kazakhstan'},
        {label: 'Kenya', value: 'Kenya'},
        {label: 'Kiribati', value: 'Kiribati'},
        {
          label: 'Corea del Nord',
          value: "Korea, Democratic People's Republic Of",
        },
        {label: 'Kosovo', value: 'Kosovo'},
        {label: 'Kuwait', value: 'Kuwait'},
        {label: 'Kirghizistan', value: 'Kyrgyzstan'},
        {label: 'Laos', value: "Lao People's Democratic Republic"},
        {label: 'Lettonia', value: 'Latvia'},
        {label: 'Libano', value: 'Lebanon'},
        {label: 'Lesotho', value: 'Lesotho'},
        {label: 'Liberia', value: 'Liberia'},
        {label: 'Libia', value: 'Libyan Arab Jamahiriya'},
        {label: 'Liechtenstein', value: 'Liechtenstein'},
        {label: 'Lituania', value: 'Lithuania'},
        {label: 'Lussemburgo', value: 'Luxembourg'},
        {label: 'RAS di Macao', value: 'Macao'},
        {label: 'Madagascar', value: 'Madagascar'},
        {label: 'Malawi', value: 'Malawi'},
        {label: 'Malaysia', value: 'Malaysia'},
        {label: 'Maldive', value: 'Maldives'},
        {label: 'Mali', value: 'Mali'},
        {label: 'Malta', value: 'Malta'},
        {label: 'Martinica', value: 'Martinique'},
        {label: 'Mauritania', value: 'Mauritania'},
        {label: 'Mauritius', value: 'Mauritius'},
        {label: 'Mayotte', value: 'Mayotte'},
        {label: 'Messico', value: 'Mexico'},
        {label: 'Moldavia', value: 'Moldova, Republic of'},
        {label: 'Monaco', value: 'Monaco'},
        {label: 'Mongolia', value: 'Mongolia'},
        {label: 'Montenegro', value: 'Montenegro'},
        {label: 'Montserrat', value: 'Montserrat'},
        {label: 'Marocco', value: 'Morocco'},
        {label: 'Mozambico', value: 'Mozambique'},
        {label: 'Myanmar (Birmania)', value: 'Myanmar'},
        {label: 'Namibia', value: 'Namibia'},
        {label: 'Nauru', value: 'Nauru'},
        {label: 'Nepal', value: 'Nepal'},
        {label: 'Paesi Bassi', value: 'Netherlands'},
        {label: 'Antille olandesi', value: 'Netherlands Antilles'},
        {label: 'Nuova Caledonia', value: 'New Caledonia'},
        {label: 'Nuova Zelanda', value: 'New Zealand'},
        {label: 'Nicaragua', value: 'Nicaragua'},
        {label: 'Niger', value: 'Niger'},
        {label: 'Nigeria', value: 'Nigeria'},
        {label: 'Niue', value: 'Niue'},
        {label: 'Isola Norfolk', value: 'Norfolk Island'},
        {label: 'Macedonia del Nord', value: 'North Macedonia'},
        {label: 'Norvegia', value: 'Norway'},
        {label: 'Oman', value: 'Oman'},
        {label: 'Pakistan', value: 'Pakistan'},
        {
          label: 'Territori palestinesi',
          value: 'Palestinian Territory, Occupied',
        },
        {label: 'Panamá', value: 'Panama'},
        {label: 'Papua Nuova Guinea', value: 'Papua New Guinea'},
        {label: 'Paraguay', value: 'Paraguay'},
        {label: 'Perù', value: 'Peru'},
        {label: 'Filippine', value: 'Philippines'},
        {label: 'Isole Pitcairn', value: 'Pitcairn'},
        {label: 'Polonia', value: 'Poland'},
        {label: 'Portogallo', value: 'Portugal'},
        {label: 'Qatar', value: 'Qatar'},
        {label: 'Camerun', value: 'Republic of Cameroon'},
        {label: 'Riunione', value: 'Reunion'},
        {label: 'Romania', value: 'Romania'},
        {label: 'Russia', value: 'Russia'},
        {label: 'Ruanda', value: 'Rwanda'},
        {label: 'Saint-Barthélemy', value: 'Saint Barthélemy'},
        {label: 'Sant’Elena', value: 'Saint Helena'},
        {label: 'Saint Kitts e Nevis', value: 'Saint Kitts And Nevis'},
        {label: 'Saint Lucia', value: 'Saint Lucia'},
        {label: 'Saint Martin', value: 'Saint Martin'},
        {label: 'Saint-Pierre e Miquelon', value: 'Saint Pierre And Miquelon'},
        {label: 'Samoa', value: 'Samoa'},
        {label: 'San Marino', value: 'San Marino'},
        {label: 'São Tomé e Príncipe', value: 'Sao Tome And Principe'},
        {label: 'Arabia Saudita', value: 'Saudi Arabia'},
        {label: 'Senegal', value: 'Senegal'},
        {label: 'Serbia', value: 'Serbia'},
        {label: 'Seychelles', value: 'Seychelles'},
        {label: 'Sierra Leone', value: 'Sierra Leone'},
        {label: 'Singapore', value: 'Singapore'},
        {label: 'Sint Maarten', value: 'Sint Maarten'},
        {label: 'Slovacchia', value: 'Slovakia'},
        {label: 'Slovenia', value: 'Slovenia'},
        {label: 'Isole Salomone', value: 'Solomon Islands'},
        {label: 'Somalia', value: 'Somalia'},
        {label: 'Sudafrica', value: 'South Africa'},
        {
          label: 'Georgia del Sud e Sandwich australi',
          value: 'South Georgia And The South Sandwich Islands',
        },
        {label: 'Corea del Sud', value: 'South Korea'},
        {label: 'Sud Sudan', value: 'South Sudan'},
        {label: 'Spagna', value: 'Spain'},
        {label: 'Sri Lanka', value: 'Sri Lanka'},
        {label: 'Saint Vincent e Grenadine', value: 'St. Vincent'},
        {label: 'Sudan', value: 'Sudan'},
        {label: 'Suriname', value: 'Suriname'},
        {label: 'Svalbard e Jan Mayen', value: 'Svalbard And Jan Mayen'},
        {label: 'Svezia', value: 'Sweden'},
        {label: 'Svizzera', value: 'Switzerland'},
        {label: 'Siria', value: 'Syria'},
        {label: 'Taiwan', value: 'Taiwan'},
        {label: 'Tagikistan', value: 'Tajikistan'},
        {label: 'Tanzania', value: 'Tanzania, United Republic Of'},
        {label: 'Thailandia', value: 'Thailand'},
        {label: 'Timor Est', value: 'Timor Leste'},
        {label: 'Togo', value: 'Togo'},
        {label: 'Tokelau', value: 'Tokelau'},
        {label: 'Tonga', value: 'Tonga'},
        {label: 'Trinidad e Tobago', value: 'Trinidad and Tobago'},
        {label: 'Tristan da Cunha', value: 'Tristan da Cunha'},
        {label: 'Tunisia', value: 'Tunisia'},
        {label: 'Turchia', value: 'Turkey'},
        {label: 'Turkmenistan', value: 'Turkmenistan'},
        {label: 'Isole Turks e Caicos', value: 'Turks and Caicos Islands'},
        {label: 'Tuvalu', value: 'Tuvalu'},
        {label: 'Uganda', value: 'Uganda'},
        {label: 'Ucraina', value: 'Ukraine'},
        {label: 'Emirati Arabi Uniti', value: 'United Arab Emirates'},
        {label: 'Regno Unito', value: 'United Kingdom'},
        {label: 'Stati Uniti', value: 'United States'},
        {
          label: 'Altre isole americane del Pacifico',
          value: 'United States Minor Outlying Islands',
        },
        {label: 'Uruguay', value: 'Uruguay'},
        {label: 'Uzbekistan', value: 'Uzbekistan'},
        {label: 'Vanuatu', value: 'Vanuatu'},
        {label: 'Venezuela', value: 'Venezuela'},
        {label: 'Vietnam', value: 'Vietnam'},
        {label: 'Isole Vergini Britanniche', value: 'Virgin Islands, British'},
        {label: 'Wallis e Futuna', value: 'Wallis And Futuna'},
        {label: 'Sahara occidentale', value: 'Western Sahara'},
        {label: 'Yemen', value: 'Yemen'},
        {label: 'Zambia', value: 'Zambia'},
        {label: 'Zimbabwe', value: 'Zimbabwe'},
      ];
    case 'ja':
      return [
        {label: '1つ選択してください', value: ''},
        {label: 'アフガニスタン', value: 'Afghanistan'},
        {label: 'オーランド諸島', value: 'Aland Islands'},
        {label: 'アルバニア', value: 'Albania'},
        {label: 'アルジェリア', value: 'Algeria'},
        {label: 'アンドラ', value: 'Andorra'},
        {label: 'アンゴラ', value: 'Angola'},
        {label: 'アンギラ', value: 'Anguilla'},
        {label: 'アンティグア・バーブーダ', value: 'Antigua And Barbuda'},
        {label: 'アルゼンチン', value: 'Argentina'},
        {label: 'アルメニア', value: 'Armenia'},
        {label: 'アルバ', value: 'Aruba'},
        {label: 'アセンション島', value: 'Ascension Island'},
        {label: 'オーストラリア', value: 'Australia'},
        {label: 'オーストリア', value: 'Austria'},
        {label: 'アゼルバイジャン', value: 'Azerbaijan'},
        {label: 'バハマ', value: 'Bahamas'},
        {label: 'バーレーン', value: 'Bahrain'},
        {label: 'バングラデシュ', value: 'Bangladesh'},
        {label: 'バルバドス', value: 'Barbados'},
        {label: 'ベラルーシ', value: 'Belarus'},
        {label: 'ベルギー', value: 'Belgium'},
        {label: 'ベリーズ', value: 'Belize'},
        {label: 'ベナン', value: 'Benin'},
        {label: 'バミューダ', value: 'Bermuda'},
        {label: 'ブータン', value: 'Bhutan'},
        {label: 'ボリビア', value: 'Bolivia'},
        {label: 'ボスニア・ヘルツェゴビナ', value: 'Bosnia And Herzegovina'},
        {label: 'ボツワナ', value: 'Botswana'},
        {label: 'ブーベ島', value: 'Bouvet Island'},
        {label: 'ブラジル', value: 'Brazil'},
        {label: '英領インド洋地域', value: 'British Indian Ocean Territory'},
        {label: 'ブルネイ', value: 'Brunei'},
        {label: 'ブルガリア', value: 'Bulgaria'},
        {label: 'ブルキナファソ', value: 'Burkina Faso'},
        {label: 'ブルンジ', value: 'Burundi'},
        {label: 'カンボジア', value: 'Cambodia'},
        {label: 'カナダ', value: 'Canada'},
        {label: 'カーボベルデ', value: 'Cape Verde'},
        {label: 'オランダ領カリブ', value: 'Caribbean Netherlands'},
        {label: 'ケイマン諸島', value: 'Cayman Islands'},
        {label: '中央アフリカ共和国', value: 'Central African Republic'},
        {label: 'チャド', value: 'Chad'},
        {label: 'チリ', value: 'Chile'},
        {label: '中国', value: 'China'},
        {label: 'クリスマス島', value: 'Christmas Island'},
        {label: 'ココス(キーリング)諸島', value: 'Cocos (Keeling) Islands'},
        {label: 'コロンビア', value: 'Colombia'},
        {label: 'コモロ', value: 'Comoros'},
        {label: 'コンゴ共和国(ブラザビル)', value: 'Congo'},
        {
          label: 'コンゴ民主共和国(キンシャサ)',
          value: 'Congo, The Democratic Republic Of The',
        },
        {label: 'クック諸島', value: 'Cook Islands'},
        {label: 'コスタリカ', value: 'Costa Rica'},
        {label: 'クロアチア', value: 'Croatia'},
        {label: 'キューバ', value: 'Cuba'},
        {label: 'キュラソー', value: 'Curaçao'},
        {label: 'キプロス', value: 'Cyprus'},
        {label: 'チェコ', value: 'Czech Republic'},
        {label: 'コートジボワール', value: "Côte d'Ivoire"},
        {label: 'デンマーク', value: 'Denmark'},
        {label: 'ジブチ', value: 'Djibouti'},
        {label: 'ドミニカ国', value: 'Dominica'},
        {label: 'ドミニカ共和国', value: 'Dominican Republic'},
        {label: 'エクアドル', value: 'Ecuador'},
        {label: 'エジプト', value: 'Egypt'},
        {label: 'エルサルバドル', value: 'El Salvador'},
        {label: '赤道ギニア', value: 'Equatorial Guinea'},
        {label: 'エリトリア', value: 'Eritrea'},
        {label: 'エストニア', value: 'Estonia'},
        {label: 'エスワティニ', value: 'Eswatini'},
        {label: 'エチオピア', value: 'Ethiopia'},
        {label: 'フォークランド諸島', value: 'Falkland Islands (Malvinas)'},
        {label: 'フェロー諸島', value: 'Faroe Islands'},
        {label: 'フィジー', value: 'Fiji'},
        {label: 'フィンランド', value: 'Finland'},
        {label: 'フランス', value: 'France'},
        {label: '仏領ギアナ', value: 'French Guiana'},
        {label: '仏領ポリネシア', value: 'French Polynesia'},
        {label: '仏領極南諸島', value: 'French Southern Territories'},
        {label: 'ガボン', value: 'Gabon'},
        {label: 'ガンビア', value: 'Gambia'},
        {label: 'ジョージア', value: 'Georgia'},
        {label: 'ドイツ', value: 'Germany'},
        {label: 'ガーナ', value: 'Ghana'},
        {label: 'ジブラルタル', value: 'Gibraltar'},
        {label: 'ギリシャ', value: 'Greece'},
        {label: 'グリーンランド', value: 'Greenland'},
        {label: 'グレナダ', value: 'Grenada'},
        {label: 'グアドループ', value: 'Guadeloupe'},
        {label: 'グアテマラ', value: 'Guatemala'},
        {label: 'ガーンジー', value: 'Guernsey'},
        {label: 'ギニア', value: 'Guinea'},
        {label: 'ギニアビサウ', value: 'Guinea Bissau'},
        {label: 'ガイアナ', value: 'Guyana'},
        {label: 'ハイチ', value: 'Haiti'},
        {
          label: 'ハード島・マクドナルド諸島',
          value: 'Heard Island And Mcdonald Islands',
        },
        {label: 'バチカン市国', value: 'Holy See (Vatican City State)'},
        {label: 'ホンジュラス', value: 'Honduras'},
        {label: '中華人民共和国香港特別行政区', value: 'Hong Kong'},
        {label: 'ハンガリー', value: 'Hungary'},
        {label: 'アイスランド', value: 'Iceland'},
        {label: 'インド', value: 'India'},
        {label: 'インドネシア', value: 'Indonesia'},
        {label: 'イラン', value: 'Iran, Islamic Republic Of'},
        {label: 'イラク', value: 'Iraq'},
        {label: 'アイルランド', value: 'Ireland'},
        {label: 'マン島', value: 'Isle Of Man'},
        {label: 'イスラエル', value: 'Israel'},
        {label: 'イタリア', value: 'Italy'},
        {label: 'ジャマイカ', value: 'Jamaica'},
        {label: '日本', value: 'Japan'},
        {label: 'ジャージー', value: 'Jersey'},
        {label: 'ヨルダン', value: 'Jordan'},
        {label: 'カザフスタン', value: 'Kazakhstan'},
        {label: 'ケニア', value: 'Kenya'},
        {label: 'キリバス', value: 'Kiribati'},
        {label: '北朝鮮', value: "Korea, Democratic People's Republic Of"},
        {label: 'コソボ', value: 'Kosovo'},
        {label: 'クウェート', value: 'Kuwait'},
        {label: 'キルギス', value: 'Kyrgyzstan'},
        {label: 'ラオス', value: "Lao People's Democratic Republic"},
        {label: 'ラトビア', value: 'Latvia'},
        {label: 'レバノン', value: 'Lebanon'},
        {label: 'レソト', value: 'Lesotho'},
        {label: 'リベリア', value: 'Liberia'},
        {label: 'リビア', value: 'Libyan Arab Jamahiriya'},
        {label: 'リヒテンシュタイン', value: 'Liechtenstein'},
        {label: 'リトアニア', value: 'Lithuania'},
        {label: 'ルクセンブルク', value: 'Luxembourg'},
        {label: '中華人民共和国マカオ特別行政区', value: 'Macao'},
        {label: 'マダガスカル', value: 'Madagascar'},
        {label: 'マラウイ', value: 'Malawi'},
        {label: 'マレーシア', value: 'Malaysia'},
        {label: 'モルディブ', value: 'Maldives'},
        {label: 'マリ', value: 'Mali'},
        {label: 'マルタ', value: 'Malta'},
        {label: 'マルティニーク', value: 'Martinique'},
        {label: 'モーリタニア', value: 'Mauritania'},
        {label: 'モーリシャス', value: 'Mauritius'},
        {label: 'マヨット', value: 'Mayotte'},
        {label: 'メキシコ', value: 'Mexico'},
        {label: 'モルドバ', value: 'Moldova, Republic of'},
        {label: 'モナコ', value: 'Monaco'},
        {label: 'モンゴル', value: 'Mongolia'},
        {label: 'モンテネグロ', value: 'Montenegro'},
        {label: 'モントセラト', value: 'Montserrat'},
        {label: 'モロッコ', value: 'Morocco'},
        {label: 'モザンビーク', value: 'Mozambique'},
        {label: 'ミャンマー (ビルマ)', value: 'Myanmar'},
        {label: 'ナミビア', value: 'Namibia'},
        {label: 'ナウル', value: 'Nauru'},
        {label: 'ネパール', value: 'Nepal'},
        {label: 'オランダ', value: 'Netherlands'},
        {label: 'オランダ領アンティル諸島', value: 'Netherlands Antilles'},
        {label: 'ニューカレドニア', value: 'New Caledonia'},
        {label: 'ニュージーランド', value: 'New Zealand'},
        {label: 'ニカラグア', value: 'Nicaragua'},
        {label: 'ニジェール', value: 'Niger'},
        {label: 'ナイジェリア', value: 'Nigeria'},
        {label: 'ニウエ', value: 'Niue'},
        {label: 'ノーフォーク島', value: 'Norfolk Island'},
        {label: '北マケドニア', value: 'North Macedonia'},
        {label: 'ノルウェー', value: 'Norway'},
        {label: 'オマーン', value: 'Oman'},
        {label: 'パキスタン', value: 'Pakistan'},
        {label: 'パレスチナ自治区', value: 'Palestinian Territory, Occupied'},
        {label: 'パナマ', value: 'Panama'},
        {label: 'パプアニューギニア', value: 'Papua New Guinea'},
        {label: 'パラグアイ', value: 'Paraguay'},
        {label: 'ペルー', value: 'Peru'},
        {label: 'フィリピン', value: 'Philippines'},
        {label: 'ピトケアン諸島', value: 'Pitcairn'},
        {label: 'ポーランド', value: 'Poland'},
        {label: 'ポルトガル', value: 'Portugal'},
        {label: 'カタール', value: 'Qatar'},
        {label: 'カメルーン', value: 'Republic of Cameroon'},
        {label: 'レユニオン', value: 'Reunion'},
        {label: 'ルーマニア', value: 'Romania'},
        {label: 'ロシア', value: 'Russia'},
        {label: 'ルワンダ', value: 'Rwanda'},
        {label: 'サン・バルテルミー', value: 'Saint Barthélemy'},
        {label: 'セントヘレナ', value: 'Saint Helena'},
        {
          label: 'セントクリストファー・ネーヴィス',
          value: 'Saint Kitts And Nevis',
        },
        {label: 'セントルシア', value: 'Saint Lucia'},
        {label: 'サン・マルタン', value: 'Saint Martin'},
        {
          label: 'サンピエール島・ミクロン島',
          value: 'Saint Pierre And Miquelon',
        },
        {label: 'サモア', value: 'Samoa'},
        {label: 'サンマリノ', value: 'San Marino'},
        {label: 'サントメ・プリンシペ', value: 'Sao Tome And Principe'},
        {label: 'サウジアラビア', value: 'Saudi Arabia'},
        {label: 'セネガル', value: 'Senegal'},
        {label: 'セルビア', value: 'Serbia'},
        {label: 'セーシェル', value: 'Seychelles'},
        {label: 'シエラレオネ', value: 'Sierra Leone'},
        {label: 'シンガポール', value: 'Singapore'},
        {label: 'シント・マールテン', value: 'Sint Maarten'},
        {label: 'スロバキア', value: 'Slovakia'},
        {label: 'スロベニア', value: 'Slovenia'},
        {label: 'ソロモン諸島', value: 'Solomon Islands'},
        {label: 'ソマリア', value: 'Somalia'},
        {label: '南アフリカ', value: 'South Africa'},
        {
          label: 'サウスジョージア・サウスサンドウィッチ諸島',
          value: 'South Georgia And The South Sandwich Islands',
        },
        {label: '韓国', value: 'South Korea'},
        {label: '南スーダン', value: 'South Sudan'},
        {label: 'スペイン', value: 'Spain'},
        {label: 'スリランカ', value: 'Sri Lanka'},
        {label: 'セントビンセント及びグレナディーン諸島', value: 'St. Vincent'},
        {label: 'スーダン', value: 'Sudan'},
        {label: 'スリナム', value: 'Suriname'},
        {
          label: 'スバールバル諸島・ヤンマイエン島',
          value: 'Svalbard And Jan Mayen',
        },
        {label: 'スウェーデン', value: 'Sweden'},
        {label: 'スイス', value: 'Switzerland'},
        {label: 'シリア', value: 'Syria'},
        {label: '台湾', value: 'Taiwan'},
        {label: 'タジキスタン', value: 'Tajikistan'},
        {label: 'タンザニア', value: 'Tanzania, United Republic Of'},
        {label: 'タイ', value: 'Thailand'},
        {label: '東ティモール', value: 'Timor Leste'},
        {label: 'トーゴ', value: 'Togo'},
        {label: 'トケラウ', value: 'Tokelau'},
        {label: 'トンガ', value: 'Tonga'},
        {label: 'トリニダード・トバゴ', value: 'Trinidad and Tobago'},
        {label: 'トリスタン・ダ・クーニャ', value: 'Tristan da Cunha'},
        {label: 'チュニジア', value: 'Tunisia'},
        {label: 'トルコ', value: 'Turkey'},
        {label: 'トルクメニスタン', value: 'Turkmenistan'},
        {label: 'タークス・カイコス諸島', value: 'Turks and Caicos Islands'},
        {label: 'ツバル', value: 'Tuvalu'},
        {label: 'ウガンダ', value: 'Uganda'},
        {label: 'ウクライナ', value: 'Ukraine'},
        {label: 'アラブ首長国連邦', value: 'United Arab Emirates'},
        {label: 'イギリス', value: 'United Kingdom'},
        {label: 'アメリカ合衆国', value: 'United States'},
        {
          label: '合衆国領有小離島',
          value: 'United States Minor Outlying Islands',
        },
        {label: 'ウルグアイ', value: 'Uruguay'},
        {label: 'ウズベキスタン', value: 'Uzbekistan'},
        {label: 'バヌアツ', value: 'Vanuatu'},
        {label: 'ベネズエラ', value: 'Venezuela'},
        {label: 'ベトナム', value: 'Vietnam'},
        {label: '英領ヴァージン諸島', value: 'Virgin Islands, British'},
        {label: 'ウォリス・フツナ', value: 'Wallis And Futuna'},
        {label: '西サハラ', value: 'Western Sahara'},
        {label: 'イエメン', value: 'Yemen'},
        {label: 'ザンビア', value: 'Zambia'},
        {label: 'ジンバブエ', value: 'Zimbabwe'},
      ];
    case 'zh-CN':
      return [
        {label: '单选', value: ''},
        {label: '阿富汗', value: 'Afghanistan'},
        {label: '奥兰群岛', value: 'Aland Islands'},
        {label: '阿尔巴尼亚', value: 'Albania'},
        {label: '阿尔及利亚', value: 'Algeria'},
        {label: '安道尔', value: 'Andorra'},
        {label: '安哥拉', value: 'Angola'},
        {label: '安圭拉', value: 'Anguilla'},
        {label: '安提瓜和巴布达', value: 'Antigua And Barbuda'},
        {label: '阿根廷', value: 'Argentina'},
        {label: '亚美尼亚', value: 'Armenia'},
        {label: '阿鲁巴', value: 'Aruba'},
        {label: '阿森松岛', value: 'Ascension Island'},
        {label: '澳大利亚', value: 'Australia'},
        {label: '奥地利', value: 'Austria'},
        {label: '阿塞拜疆', value: 'Azerbaijan'},
        {label: '巴哈马', value: 'Bahamas'},
        {label: '巴林', value: 'Bahrain'},
        {label: '孟加拉国', value: 'Bangladesh'},
        {label: '巴巴多斯', value: 'Barbados'},
        {label: '白俄罗斯', value: 'Belarus'},
        {label: '比利时', value: 'Belgium'},
        {label: '伯利兹', value: 'Belize'},
        {label: '贝宁', value: 'Benin'},
        {label: '百慕大', value: 'Bermuda'},
        {label: '不丹', value: 'Bhutan'},
        {label: '玻利维亚', value: 'Bolivia'},
        {label: '波斯尼亚和黑塞哥维那', value: 'Bosnia And Herzegovina'},
        {label: '博茨瓦纳', value: 'Botswana'},
        {label: '布韦岛', value: 'Bouvet Island'},
        {label: '巴西', value: 'Brazil'},
        {label: '英属印度洋领地', value: 'British Indian Ocean Territory'},
        {label: '文莱', value: 'Brunei'},
        {label: '保加利亚', value: 'Bulgaria'},
        {label: '布基纳法索', value: 'Burkina Faso'},
        {label: '布隆迪', value: 'Burundi'},
        {label: '柬埔寨', value: 'Cambodia'},
        {label: '加拿大', value: 'Canada'},
        {label: '佛得角', value: 'Cape Verde'},
        {label: '荷属加勒比区', value: 'Caribbean Netherlands'},
        {label: '开曼群岛', value: 'Cayman Islands'},
        {label: '中非共和国', value: 'Central African Republic'},
        {label: '乍得', value: 'Chad'},
        {label: '智利', value: 'Chile'},
        {label: '中国', value: 'China'},
        {label: '圣诞岛', value: 'Christmas Island'},
        {label: '科科斯（基林）群岛', value: 'Cocos (Keeling) Islands'},
        {label: '哥伦比亚', value: 'Colombia'},
        {label: '科摩罗', value: 'Comoros'},
        {label: '刚果（布）', value: 'Congo'},
        {label: '刚果（金）', value: 'Congo, The Democratic Republic Of The'},
        {label: '库克群岛', value: 'Cook Islands'},
        {label: '哥斯达黎加', value: 'Costa Rica'},
        {label: '克罗地亚', value: 'Croatia'},
        {label: '古巴', value: 'Cuba'},
        {label: '库拉索', value: 'Curaçao'},
        {label: '塞浦路斯', value: 'Cyprus'},
        {label: '捷克', value: 'Czech Republic'},
        {label: '科特迪瓦', value: "Côte d'Ivoire"},
        {label: '丹麦', value: 'Denmark'},
        {label: '吉布提', value: 'Djibouti'},
        {label: '多米尼克', value: 'Dominica'},
        {label: '多米尼加共和国', value: 'Dominican Republic'},
        {label: '厄瓜多尔', value: 'Ecuador'},
        {label: '埃及', value: 'Egypt'},
        {label: '萨尔瓦多', value: 'El Salvador'},
        {label: '赤道几内亚', value: 'Equatorial Guinea'},
        {label: '厄立特里亚', value: 'Eritrea'},
        {label: '爱沙尼亚', value: 'Estonia'},
        {label: '斯威士兰', value: 'Eswatini'},
        {label: '埃塞俄比亚', value: 'Ethiopia'},
        {label: '福克兰群岛', value: 'Falkland Islands (Malvinas)'},
        {label: '法罗群岛', value: 'Faroe Islands'},
        {label: '斐济', value: 'Fiji'},
        {label: '芬兰', value: 'Finland'},
        {label: '法国', value: 'France'},
        {label: '法属圭亚那', value: 'French Guiana'},
        {label: '法属波利尼西亚', value: 'French Polynesia'},
        {label: '法属南部领地', value: 'French Southern Territories'},
        {label: '加蓬', value: 'Gabon'},
        {label: '冈比亚', value: 'Gambia'},
        {label: '格鲁吉亚', value: 'Georgia'},
        {label: '德国', value: 'Germany'},
        {label: '加纳', value: 'Ghana'},
        {label: '直布罗陀', value: 'Gibraltar'},
        {label: '希腊', value: 'Greece'},
        {label: '格陵兰', value: 'Greenland'},
        {label: '格林纳达', value: 'Grenada'},
        {label: '瓜德罗普', value: 'Guadeloupe'},
        {label: '危地马拉', value: 'Guatemala'},
        {label: '根西岛', value: 'Guernsey'},
        {label: '几内亚', value: 'Guinea'},
        {label: '几内亚比绍', value: 'Guinea Bissau'},
        {label: '圭亚那', value: 'Guyana'},
        {label: '海地', value: 'Haiti'},
        {
          label: '赫德岛和麦克唐纳群岛',
          value: 'Heard Island And Mcdonald Islands',
        },
        {label: '梵蒂冈', value: 'Holy See (Vatican City State)'},
        {label: '洪都拉斯', value: 'Honduras'},
        {label: '中国香港特别行政区', value: 'Hong Kong'},
        {label: '匈牙利', value: 'Hungary'},
        {label: '冰岛', value: 'Iceland'},
        {label: '印度', value: 'India'},
        {label: '印度尼西亚', value: 'Indonesia'},
        {label: '伊朗', value: 'Iran, Islamic Republic Of'},
        {label: '伊拉克', value: 'Iraq'},
        {label: '爱尔兰', value: 'Ireland'},
        {label: '马恩岛', value: 'Isle Of Man'},
        {label: '以色列', value: 'Israel'},
        {label: '意大利', value: 'Italy'},
        {label: '牙买加', value: 'Jamaica'},
        {label: '日本', value: 'Japan'},
        {label: '泽西岛', value: 'Jersey'},
        {label: '约旦', value: 'Jordan'},
        {label: '哈萨克斯坦', value: 'Kazakhstan'},
        {label: '肯尼亚', value: 'Kenya'},
        {label: '基里巴斯', value: 'Kiribati'},
        {label: '朝鲜', value: "Korea, Democratic People's Republic Of"},
        {label: '科索沃', value: 'Kosovo'},
        {label: '科威特', value: 'Kuwait'},
        {label: '吉尔吉斯斯坦', value: 'Kyrgyzstan'},
        {label: '老挝', value: "Lao People's Democratic Republic"},
        {label: '拉脱维亚', value: 'Latvia'},
        {label: '黎巴嫩', value: 'Lebanon'},
        {label: '莱索托', value: 'Lesotho'},
        {label: '利比里亚', value: 'Liberia'},
        {label: '利比亚', value: 'Libyan Arab Jamahiriya'},
        {label: '列支敦士登', value: 'Liechtenstein'},
        {label: '立陶宛', value: 'Lithuania'},
        {label: '卢森堡', value: 'Luxembourg'},
        {label: '中国澳门特别行政区', value: 'Macao'},
        {label: '马达加斯加', value: 'Madagascar'},
        {label: '马拉维', value: 'Malawi'},
        {label: '马来西亚', value: 'Malaysia'},
        {label: '马尔代夫', value: 'Maldives'},
        {label: '马里', value: 'Mali'},
        {label: '马耳他', value: 'Malta'},
        {label: '马提尼克', value: 'Martinique'},
        {label: '毛里塔尼亚', value: 'Mauritania'},
        {label: '毛里求斯', value: 'Mauritius'},
        {label: '马约特', value: 'Mayotte'},
        {label: '墨西哥', value: 'Mexico'},
        {label: '摩尔多瓦', value: 'Moldova, Republic of'},
        {label: '摩纳哥', value: 'Monaco'},
        {label: '蒙古', value: 'Mongolia'},
        {label: '黑山', value: 'Montenegro'},
        {label: '蒙特塞拉特', value: 'Montserrat'},
        {label: '摩洛哥', value: 'Morocco'},
        {label: '莫桑比克', value: 'Mozambique'},
        {label: '缅甸', value: 'Myanmar'},
        {label: '纳米比亚', value: 'Namibia'},
        {label: '瑙鲁', value: 'Nauru'},
        {label: '尼泊尔', value: 'Nepal'},
        {label: '荷兰', value: 'Netherlands'},
        {label: '荷属安的列斯群島', value: 'Netherlands Antilles'},
        {label: '新喀里多尼亚', value: 'New Caledonia'},
        {label: '新西兰', value: 'New Zealand'},
        {label: '尼加拉瓜', value: 'Nicaragua'},
        {label: '尼日尔', value: 'Niger'},
        {label: '尼日利亚', value: 'Nigeria'},
        {label: '纽埃', value: 'Niue'},
        {label: '诺福克岛', value: 'Norfolk Island'},
        {label: '北马其顿', value: 'North Macedonia'},
        {label: '挪威', value: 'Norway'},
        {label: '阿曼', value: 'Oman'},
        {label: '巴基斯坦', value: 'Pakistan'},
        {label: '巴勒斯坦领土', value: 'Palestinian Territory, Occupied'},
        {label: '巴拿马', value: 'Panama'},
        {label: '巴布亚新几内亚', value: 'Papua New Guinea'},
        {label: '巴拉圭', value: 'Paraguay'},
        {label: '秘鲁', value: 'Peru'},
        {label: '菲律宾', value: 'Philippines'},
        {label: '皮特凯恩群岛', value: 'Pitcairn'},
        {label: '波兰', value: 'Poland'},
        {label: '葡萄牙', value: 'Portugal'},
        {label: '卡塔尔', value: 'Qatar'},
        {label: '喀麦隆', value: 'Republic of Cameroon'},
        {label: '留尼汪', value: 'Reunion'},
        {label: '罗马尼亚', value: 'Romania'},
        {label: '俄罗斯', value: 'Russia'},
        {label: '卢旺达', value: 'Rwanda'},
        {label: '圣巴泰勒米', value: 'Saint Barthélemy'},
        {label: '圣赫勒拿', value: 'Saint Helena'},
        {label: '圣基茨和尼维斯', value: 'Saint Kitts And Nevis'},
        {label: '圣卢西亚', value: 'Saint Lucia'},
        {label: '法属圣马丁', value: 'Saint Martin'},
        {label: '圣皮埃尔和密克隆群岛', value: 'Saint Pierre And Miquelon'},
        {label: '萨摩亚', value: 'Samoa'},
        {label: '圣马力诺', value: 'San Marino'},
        {label: '圣多美和普林西比', value: 'Sao Tome And Principe'},
        {label: '沙特阿拉伯', value: 'Saudi Arabia'},
        {label: '塞内加尔', value: 'Senegal'},
        {label: '塞尔维亚', value: 'Serbia'},
        {label: '塞舌尔', value: 'Seychelles'},
        {label: '塞拉利昂', value: 'Sierra Leone'},
        {label: '新加坡', value: 'Singapore'},
        {label: '荷属圣马丁', value: 'Sint Maarten'},
        {label: '斯洛伐克', value: 'Slovakia'},
        {label: '斯洛文尼亚', value: 'Slovenia'},
        {label: '所罗门群岛', value: 'Solomon Islands'},
        {label: '索马里', value: 'Somalia'},
        {label: '南非', value: 'South Africa'},
        {
          label: '南乔治亚和南桑威奇群岛',
          value: 'South Georgia And The South Sandwich Islands',
        },
        {label: '韩国', value: 'South Korea'},
        {label: '南苏丹', value: 'South Sudan'},
        {label: '西班牙', value: 'Spain'},
        {label: '斯里兰卡', value: 'Sri Lanka'},
        {label: '圣文森特和格林纳丁斯', value: 'St. Vincent'},
        {label: '苏丹', value: 'Sudan'},
        {label: '苏里南', value: 'Suriname'},
        {label: '斯瓦尔巴和扬马延', value: 'Svalbard And Jan Mayen'},
        {label: '瑞典', value: 'Sweden'},
        {label: '瑞士', value: 'Switzerland'},
        {label: '叙利亚', value: 'Syria'},
        {label: '台湾', value: 'Taiwan'},
        {label: '塔吉克斯坦', value: 'Tajikistan'},
        {label: '坦桑尼亚', value: 'Tanzania, United Republic Of'},
        {label: '泰国', value: 'Thailand'},
        {label: '东帝汶', value: 'Timor Leste'},
        {label: '多哥', value: 'Togo'},
        {label: '托克劳', value: 'Tokelau'},
        {label: '汤加', value: 'Tonga'},
        {label: '特立尼达和多巴哥', value: 'Trinidad and Tobago'},
        {label: '特里斯坦-达库尼亚群岛', value: 'Tristan da Cunha'},
        {label: '突尼斯', value: 'Tunisia'},
        {label: '土耳其', value: 'Turkey'},
        {label: '土库曼斯坦', value: 'Turkmenistan'},
        {label: '特克斯和凯科斯群岛', value: 'Turks and Caicos Islands'},
        {label: '图瓦卢', value: 'Tuvalu'},
        {label: '乌干达', value: 'Uganda'},
        {label: '乌克兰', value: 'Ukraine'},
        {label: '阿拉伯联合酋长国', value: 'United Arab Emirates'},
        {label: '英国', value: 'United Kingdom'},
        {label: '美国', value: 'United States'},
        {
          label: '美国本土外小岛屿',
          value: 'United States Minor Outlying Islands',
        },
        {label: '乌拉圭', value: 'Uruguay'},
        {label: '乌兹别克斯坦', value: 'Uzbekistan'},
        {label: '瓦努阿图', value: 'Vanuatu'},
        {label: '委内瑞拉', value: 'Venezuela'},
        {label: '越南', value: 'Vietnam'},
        {label: '英属维尔京群岛', value: 'Virgin Islands, British'},
        {label: '瓦利斯和富图纳', value: 'Wallis And Futuna'},
        {label: '西撒哈拉', value: 'Western Sahara'},
        {label: '也门', value: 'Yemen'},
        {label: '赞比亚', value: 'Zambia'},
        {label: '津巴布韦', value: 'Zimbabwe'},
      ];
    default:
      return [
        {label: 'Select one', value: ''},
        {label: 'Afghanistan', value: 'Afghanistan'},
        {label: 'Aland Islands', value: 'Åland Islands'},
        {label: 'Albania', value: 'Albania'},
        {label: 'Algeria', value: 'Algeria'},
        {label: 'Andorra', value: 'Andorra'},
        {label: 'Angola', value: 'Angola'},
        {label: 'Anguilla', value: 'Anguilla'},
        {label: 'Antigua And Barbuda', value: 'Antigua & amp; Barbuda'},
        {label: 'Argentina', value: 'Argentina'},
        {label: 'Armenia', value: 'Armenia'},
        {label: 'Aruba', value: 'Aruba'},
        {label: 'Ascension Island', value: 'Ascension Island'},
        {label: 'Australia', value: 'Australia'},
        {label: 'Austria', value: 'Austria'},
        {label: 'Azerbaijan', value: 'Azerbaijan'},
        {label: 'Bahamas', value: 'Bahamas'},
        {label: 'Bahrain', value: 'Bahrain'},
        {label: 'Bangladesh', value: 'Bangladesh'},
        {label: 'Barbados', value: 'Barbados'},
        {label: 'Belarus', value: 'Belarus'},
        {label: 'Belgium', value: 'Belgium'},
        {label: 'Belize', value: 'Belize'},
        {label: 'Benin', value: 'Benin'},
        {label: 'Bermuda', value: 'Bermuda'},
        {label: 'Bhutan', value: 'Bhutan'},
        {label: 'Bolivia', value: 'Bolivia'},
        {label: 'Bosnia And Herzegovina', value: 'Bosnia & amp; Herzegovina'},
        {label: 'Botswana', value: 'Botswana'},
        {label: 'Bouvet Island', value: 'Bouvet Island'},
        {label: 'Brazil', value: 'Brazil'},
        {
          label: 'British Indian Ocean Territory',
          value: 'British Indian Ocean Territory',
        },
        {label: 'Brunei', value: 'Brunei'},
        {label: 'Bulgaria', value: 'Bulgaria'},
        {label: 'Burkina Faso', value: 'Burkina Faso'},
        {label: 'Burundi', value: 'Burundi'},
        {label: 'Cambodia', value: 'Cambodia'},
        {label: 'Canada', value: 'Canada'},
        {label: 'Cape Verde', value: 'Cape Verde'},
        {label: 'Caribbean Netherlands', value: 'Caribbean Netherlands'},
        {label: 'Cayman Islands', value: 'Cayman Islands'},
        {label: 'Central African Republic', value: 'Central African Republic'},
        {label: 'Chad', value: 'Chad'},
        {label: 'Chile', value: 'Chile'},
        {label: 'China', value: 'China'},
        {label: 'Christmas Island', value: 'Christmas Island'},
        {label: 'Cocos(Keeling) Islands', value: 'Cocos(Keeling) Islands'},
        {label: 'Colombia', value: 'Colombia'},
        {label: 'Comoros', value: 'Comoros'},
        {label: 'Congo', value: 'Congo - Brazzaville'},
        {
          label: 'Congo, The Democratic Republic Of The',
          value: 'Congo - Kinshasa',
        },
        {label: 'Cook Islands', value: 'Cook Islands'},
        {label: 'Costa Rica', value: 'Costa Rica'},
        {label: 'Croatia', value: 'Croatia'},
        {label: 'Cuba', value: 'Cuba'},
        {label: 'Curaçao', value: 'Curaçao'},
        {label: 'Cyprus', value: 'Cyprus'},
        {label: 'Czech Republic', value: 'Czechia'},
        {label: 'Côte d’Ivoire', value: 'Côte d’Ivoire'},
        {label: 'Denmark', value: 'Denmark'},
        {label: 'Djibouti', value: 'Djibouti'},
        {label: 'Dominica', value: 'Dominica'},
        {label: 'Dominican Republic', value: 'Dominican Republic'},
        {label: 'Ecuador', value: 'Ecuador'},
        {label: 'Egypt', value: 'Egypt'},
        {label: 'El Salvador', value: 'El Salvador'},
        {label: 'Equatorial Guinea', value: 'Equatorial Guinea'},
        {label: 'Eritrea', value: 'Eritrea'},
        {label: 'Estonia', value: 'Estonia'},
        {label: 'Eswatini', value: 'Eswatini'},
        {label: 'Ethiopia', value: 'Ethiopia'},
        {label: 'Falkland Islands(Malvinas)', value: 'Falkland Islands'},
        {label: 'Faroe Islands', value: 'Faroe Islands'},
        {label: 'Fiji', value: 'Fiji'},
        {label: 'Finland', value: 'Finland'},
        {label: 'France', value: 'France'},
        {label: 'French Guiana', value: 'French Guiana'},
        {label: 'French Polynesia', value: 'French Polynesia'},
        {
          label: 'French Southern Territories',
          value: 'French Southern Territories',
        },
        {label: 'Gabon', value: 'Gabon'},
        {label: 'Gambia', value: 'Gambia'},
        {label: 'Georgia', value: 'Georgia'},
        {label: 'Germany', value: 'Germany'},
        {label: 'Ghana', value: 'Ghana'},
        {label: 'Gibraltar', value: 'Gibraltar'},
        {label: 'Greece', value: 'Greece'},
        {label: 'Greenland', value: 'Greenland'},
        {label: 'Grenada', value: 'Grenada'},
        {label: 'Guadeloupe', value: 'Guadeloupe'},
        {label: 'Guatemala', value: 'Guatemala'},
        {label: 'Guernsey', value: 'Guernsey'},
        {label: 'Guinea', value: 'Guinea'},
        {label: 'Guinea Bissau', value: 'Guinea - Bissau'},
        {label: 'Guyana', value: 'Guyana'},
        {label: 'Haiti', value: 'Haiti'},
        {
          label: 'Heard Island And Mcdonald Islands',
          value: 'Heard & amp; McDonald Islands',
        },
        {label: 'Holy See(Vatican City State)', value: 'Vatican City'},
        {label: 'Honduras', value: 'Honduras'},
        {label: 'Hong Kong', value: 'Hong Kong SAR'},
        {label: 'Hungary', value: 'Hungary'},
        {label: 'Iceland', value: 'Iceland'},
        {label: 'India', value: 'India'},
        {label: 'Indonesia', value: 'Indonesia'},
        {label: 'Iran, Islamic Republic Of', value: 'Iran'},
        {label: 'Iraq', value: 'Iraq'},
        {label: 'Ireland', value: 'Ireland'},
        {label: 'Isle Of Man', value: 'Isle of Man'},
        {label: 'Israel', value: 'Israel'},
        {label: 'Italy', value: 'Italy'},
        {label: 'Jamaica', value: 'Jamaica'},
        {label: 'Japan', value: 'Japan'},
        {label: 'Jersey', value: 'Jersey'},
        {label: 'Jordan', value: 'Jordan'},
        {label: 'Kazakhstan', value: 'Kazakhstan'},
        {label: 'Kenya', value: 'Kenya'},
        {label: 'Kiribati', value: 'Kiribati'},
        {label: 'Korea, Democratic People’s Republic Of', value: 'North Korea'},
        {label: 'Kosovo', value: 'Kosovo'},
        {label: 'Kuwait', value: 'Kuwait'},
        {label: 'Kyrgyzstan', value: 'Kyrgyzstan'},
        {label: 'Lao People’s Democratic Republic', value: 'Laos'},
        {label: 'Latvia', value: 'Latvia'},
        {label: 'Lebanon', value: 'Lebanon'},
        {label: 'Lesotho', value: 'Lesotho'},
        {label: 'Liberia', value: 'Liberia'},
        {label: 'Libyan Arab Jamahiriya', value: 'Libya'},
        {label: 'Liechtenstein', value: 'Liechtenstein'},
        {label: 'Lithuania', value: 'Lithuania'},
        {label: 'Luxembourg', value: 'Luxembourg'},
        {label: 'Macao', value: 'Macao SAR'},
        {label: 'Madagascar', value: 'Madagascar'},
        {label: 'Malawi', value: 'Malawi'},
        {label: 'Malaysia', value: 'Malaysia'},
        {label: 'Maldives', value: 'Maldives'},
        {label: 'Mali', value: 'Mali'},
        {label: 'Malta', value: 'Malta'},
        {label: 'Martinique', value: 'Martinique'},
        {label: 'Mauritania', value: 'Mauritania'},
        {label: 'Mauritius', value: 'Mauritius'},
        {label: 'Mayotte', value: 'Mayotte'},
        {label: 'Mexico', value: 'Mexico'},
        {label: 'Moldova, Republic of', value: 'Moldova'},
        {label: 'Monaco', value: 'Monaco'},
        {label: 'Mongolia', value: 'Mongolia'},
        {label: 'Montenegro', value: 'Montenegro'},
        {label: 'Montserrat', value: 'Montserrat'},
        {label: 'Morocco', value: 'Morocco'},
        {label: 'Mozambique', value: 'Mozambique'},
        {label: 'Myanmar', value: 'Myanmar(Burma'},
        {label: 'Namibia', value: 'Namibia'},
        {label: 'Nauru', value: 'Nauru'},
        {label: 'Nepal', value: 'Nepal'},
        {label: 'Netherlands', value: 'Netherlands'},
        {label: 'Netherlands Antilles', value: 'Netherlands Antilles'},
        {label: 'New Caledonia', value: 'New Caledonia'},
        {label: 'New Zealand', value: 'New Zealand'},
        {label: 'Nicaragua', value: 'Nicaragua'},
        {label: 'Niger', value: 'Niger'},
        {label: 'Nigeria', value: 'Nigeria'},
        {label: 'Niue', value: 'Niue'},
        {label: 'Norfolk Island', value: 'Norfolk Island'},
        {label: 'North Macedonia', value: 'North Macedonia'},
        {label: 'Norway', value: 'Norway'},
        {label: 'Oman', value: 'Oman'},
        {label: 'Pakistan', value: 'Pakistan'},
        {
          label: 'Palestinian Territory, Occupied',
          value: 'Palestinian Territories',
        },
        {label: 'Panama', value: 'Panama'},
        {label: 'Papua New Guinea', value: 'Papua New Guinea'},
        {label: 'Paraguay', value: 'Paraguay'},
        {label: 'Peru', value: 'Peru'},
        {label: 'Philippines', value: 'Philippines'},
        {label: 'Pitcairn', value: 'Pitcairn Islands'},
        {label: 'Poland', value: 'Poland'},
        {label: 'Portugal', value: 'Portugal'},
        {label: 'Qatar', value: 'Qatar'},
        {label: 'Republic of Cameroon', value: 'Cameroon'},
        {label: 'Reunion', value: 'Réunion'},
        {label: 'Romania', value: 'Romania'},
        {label: 'Russia', value: 'Russia'},
        {label: 'Rwanda', value: 'Rwanda'},
        {label: 'Saint Barthélemy', value: 'St.Barthélemy'},
        {label: 'Saint Helena', value: 'St.Helena'},
        {label: 'Saint Kitts And Nevis', value: 'St.Kitts & amp; Nevis'},
        {label: 'Saint Lucia', value: 'St.Lucia'},
        {label: 'Saint Martin', value: 'St.Martin'},
        {
          label: 'Saint Pierre And Miquelon',
          value: 'St.Pierre & amp; Miquelon',
        },
        {label: 'Samoa', value: 'Samoa'},
        {label: 'San Marino', value: 'San Marino'},
        {label: 'Sao Tome And Principe', value: 'São Tomé & amp; Príncipe'},
        {label: 'Saudi Arabia', value: 'Saudi Arabia'},
        {label: 'Senegal', value: 'Senegal'},
        {label: 'Serbia', value: 'Serbia'},
        {label: 'Seychelles', value: 'Seychelles'},
        {label: 'Sierra Leone', value: 'Sierra Leone'},
        {label: 'Singapore', value: 'Singapore'},
        {label: 'Sint Maarten', value: 'Sint Maarten'},
        {label: 'Slovakia', value: 'Slovakia'},
        {label: 'Slovenia', value: 'Slovenia'},
        {label: 'Solomon Islands', value: 'Solomon Islands'},
        {label: 'Somalia', value: 'Somalia'},
        {label: 'South Africa', value: 'South Africa'},
        {
          label: 'South Georgia And The South Sandwich Islands',
          value: 'South Georgia & amp; South Sandwich Islands',
        },
        {label: 'South Korea', value: 'South Korea'},
        {label: 'South Sudan', value: 'South Sudan'},
        {label: 'Spain', value: 'Spain'},
        {label: 'Sri Lanka', value: 'Sri Lanka'},
        {label: 'St.Vincent', value: 'St.Vincent & amp; Grenadines'},
        {label: 'Sudan', value: 'Sudan'},
        {label: 'Suriname', value: 'Suriname'},
        {label: 'Svalbard And Jan Mayen', value: 'Svalbard & amp; Jan Mayen'},
        {label: 'Sweden', value: 'Sweden'},
        {label: 'Switzerland', value: 'Switzerland'},
        {label: 'Syria', value: 'Syria'},
        {label: 'Taiwan', value: 'Taiwan'},
        {label: 'Tajikistan', value: 'Tajikistan'},
        {label: 'Tanzania, United Republic Of', value: 'Tanzania'},
        {label: 'Thailand', value: 'Thailand'},
        {label: 'Timor Leste', value: 'Timor - Leste'},
        {label: 'Togo', value: 'Togo'},
        {label: 'Tokelau', value: 'Tokelau'},
        {label: 'Tonga', value: 'Tonga'},
        {label: 'Trinidad and Tobago', value: 'Trinidad & amp; Tobago'},
        {label: 'Tristan da Cunha', value: 'Tristan da Cunha'},
        {label: 'Tunisia', value: 'Tunisia'},
        {label: 'Turkey', value: 'Turkey'},
        {label: 'Turkmenistan', value: 'Turkmenistan'},
        {
          label: 'Turks and Caicos Islands',
          value: 'Turks & amp; Caicos Islands',
        },
        {label: 'Tuvalu', value: 'Tuvalu'},
        {label: 'Uganda', value: 'Uganda'},
        {label: 'Ukraine', value: 'Ukraine'},
        {label: 'United Arab Emirates', value: 'United Arab Emirates'},
        {label: 'United Kingdom', value: 'United Kingdom'},
        {label: 'United States', value: 'United States'},
        {
          label: 'United States Minor Outlying Islands',
          value: 'U.S.Outlying Islands',
        },
        {label: 'Uruguay', value: 'Uruguay'},
        {label: 'Uzbekistan', value: 'Uzbekistan'},
        {label: 'Vanuatu', value: 'Vanuatu'},
        {label: 'Venezuela', value: 'Venezuela'},
        {label: 'Vietnam', value: 'Vietnam'},
        {label: 'Virgin Islands, British', value: 'British Virgin Islands'},
        {label: 'Wallis And Futuna', value: 'Wallis &amp; Futuna'},
        {label: 'Western Sahara', value: 'Western Sahara'},
        {label: 'Yemen', value: 'Yemen'},
        {label: 'Zambia', value: 'Zambia'},
        {label: 'Zimbabwe', value: 'Zimbabwe'},
      ];
  }
}
